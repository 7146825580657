import React from 'react'
import cx from 'classnames'
import { Grid } from '../grid/Grid'
import { GridCol } from '../grid/GridCol'

const CLASS_ROOT = 'loader'

interface LoaderOwnProps {
  className?: string
  isCentered?: boolean
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
  white?: boolean
  loaderWrapperProps?: object
}

export type LoaderProps = React.HTMLAttributes<HTMLDivElement> & LoaderOwnProps

const Loader: React.SFC<LoaderProps> = ({
  className,
  isCentered,
  size = 'xxlarge',
  white = false,
  loaderWrapperProps,
  ...other
}) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${size}`]: size,
      [`${CLASS_ROOT}--centered`]: isCentered,
      [`${CLASS_ROOT}--white`]: white,
    },
    className
  )
  const boxes = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  return (
    <div className="loader-wrapper" {...loaderWrapperProps}>
      <Grid className={classes} {...other}>
        {boxes.map((_box, id) => (
          <GridCol
            size={4}
            key={id.toString()}
            className={`${CLASS_ROOT}-box ${CLASS_ROOT}-box-${id}`}
          />
        ))}
      </Grid>
    </div>
  )
}

export { Loader }
