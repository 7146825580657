import React from 'react'
import cx from 'classnames'
import { withUniqueId } from 'react-maria'
import { MapStatic } from './Map.static'
import { clientOnly } from '../../utils/hoc'

const CLASS_ROOT = 'map'

interface MapProps {
  uniqueId: string
  /** Options for the map https://developers.google.com/maps/documentation/javascript/reference/3.exp/map */
  mapOptions?: object
  /** Array of locations */
  locations: object[]
  className?: string
}

class MapInner extends React.Component<MapProps> {
  map: MapStatic | null = null

  async componentDidMount() {
    const gmaps = (await import('google-maps')).default
    const { uniqueId, mapOptions = {}, locations } = this.props

    this.map = new MapStatic(gmaps, uniqueId, locations, mapOptions)
  }

  render() {
    const { className, uniqueId } = this.props
    const classes = cx(CLASS_ROOT, className)

    return <div id={uniqueId} className={classes} />
  }
}

export const Map = clientOnly(withUniqueId()(MapInner))
