import Popper from 'popper.js'
import tabbable from 'tabbable'

export const TOGGLE_EVT = 'toggle'

export const keyCodes = {
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  ARROWLEFT: 37,
  ARROWUP: 38,
  ARROWRIGHT: 39,
  ARROWDOWN: 40,
}

const defaultConfig = {
  anchorElement: null,
  dataInteractive: 'data-dropdown-interactive',
  dropdownRootSelector: 'root-dropdowns',
  appRootSelector: '__next',
  popperOptions: {
    placement: 'bottom',
    removeOnDestroy: !true,
  },
}

const isActive = (el: any) => el.classList.contains('is-active')

export default class Dropdown {
  config: any

  anchorElement: any

  focusableDescendants: any[]

  instance!: Popper

  trigger: any

  constructor(public element: any, config: any) {
    this.config = { ...defaultConfig, ...config }
    this.config = {
      ...this.config,
      dropdownRoot: document.getElementById(this.config.dropdownRootSelector),
      appRoot: document.getElementById(this.config.appRootSelector),
    }

    this.anchorElement = this.config.anchorElement

    this.handleToggle = this.handleToggle.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleKeydown = this.handleKeydown.bind(this)
    this.handleOpenTransition = this.handleOpenTransition.bind(this)

    this.focusableDescendants = []

    this.init()

    return this
  }

  destroy() {
    this.element.removeEventListener(TOGGLE_EVT, this.handleToggle)
    this.instance.destroy()
    this.removeListeners()
  }

  update() {
    this.destroy()
    this.init()
  }

  init() {
    if (!this.anchorElement) {
      this.anchorElement = document.querySelector(
        `[data-dropdown-anchor=${this.element.getAttribute('id')}]`
      )
    }

    const popperOptions = {
      ...this.config.popperOptions,
      ...JSON.parse(this.element.getAttribute('data-popper-options')),
    }

    // this.transportDropdown()
    this.adjustWidth()
    this.element.addEventListener(TOGGLE_EVT, this.handleToggle)
    this.instance = new Popper(this.anchorElement, this.element, popperOptions)
    this.instance.disableEventListeners()
  }

  handleToggle(e: any) {
    this.trigger = e.detail.trigger
    this.instance.update()
    // attach events only if dropdown is open
    if (isActive(this.element)) {
      this.adjustWidth()
      this.addListeners()
      this.instance.enableEventListeners()
      this.element.setAttribute('tabindex', '0')

      this.element.addEventListener('transitionend', this.handleOpenTransition, { once: true })
    } else {
      this.element.setAttribute('tabindex', '-1')
      this.instance.disableEventListeners()
    }
  }

  handleClick(e: any) {
    // cez podmienku prelezie len klik mimo btnu
    // if (!this.anchorElement.contains(e.target)) {
    if (!this.anchorElement.contains(e.target)) {
      if (
        !this.element.hasAttribute(this.config.dataInteractive) ||
        (!this.element.contains(e.target) && this.config.appRoot.contains(e.target))
      ) {
        // we shouldn't handle tirgger toggle actions here
        this.trigger.click()
      }
    } else {
      this.removeListeners()
    }
  }

  handleOpenTransition() {
    this.element.focus()
    this.focusableDescendants = tabbable(this.element)
  }

  handleKeydown(e: any) {
    const activeDescendantIndex = this.focusableDescendants.indexOf(document.activeElement)

    if (e.which === keyCodes.ARROWDOWN) {
      e.preventDefault()
      if (document.activeElement === this.element) {
        this.focusableDescendants[0].focus()
      } else if (
        activeDescendantIndex >= 0 &&
        activeDescendantIndex < this.focusableDescendants.length - 1
      ) {
        this.focusableDescendants[activeDescendantIndex + 1].focus()
      }
    }

    if (e.which === keyCodes.ARROWUP) {
      e.preventDefault()
      if (activeDescendantIndex >= 1) {
        this.focusableDescendants[activeDescendantIndex - 1].focus()
      }
    }

    if (e.which === keyCodes.ESC) {
      e.preventDefault()
      this.trigger.focus()
      this.trigger.click()
    }

    if (e.which === keyCodes.TAB) {
      if (!e.shiftKey && activeDescendantIndex === this.focusableDescendants.length - 1) {
        e.preventDefault()
        this.trigger.click()

        window.requestAnimationFrame(() => {
          const focusable = tabbable(document.body)
          focusable[focusable.indexOf(this.trigger) + 1].focus()
        })
      }

      if (e.shiftKey && document.activeElement === this.trigger) {
        this.trigger.click()
      }

      if (
        e.shiftKey &&
        (activeDescendantIndex === 0 || activeDescendantIndex === -1) &&
        document.activeElement !== this.trigger
      ) {
        e.preventDefault()
        this.trigger.focus()
      }
    }
  }

  handleFocus() {
    if (
      this.trigger !== document.activeElement &&
      this.element !== document.activeElement &&
      !this.anchorElement.contains(document.activeElement) &&
      this.config.appRoot.contains(document.activeElement)
    ) {
      this.trigger.click()
      this.removeListeners()
    }
  }

  adjustWidth() {
    this.element.style.minWidth = getComputedStyle(this.config.anchorElement).width
  }

  // transportDropdown() {
  // this.config.dropdownRoot.appendChild(this.element)
  // }

  addListeners() {
    document.addEventListener('click', this.handleClick, true)
    document.addEventListener('focus', this.handleFocus, true)

    if (!this.element.hasAttribute(this.config.dataInteractive)) {
      document.addEventListener('keydown', this.handleKeydown, true)
    }
  }

  removeListeners() {
    document.removeEventListener('click', this.handleClick, true)
    document.removeEventListener('focus', this.handleFocus, true)

    if (this.element) {
      if (!this.element.hasAttribute(this.config.dataInteractive)) {
        document.removeEventListener('keydown', this.handleKeydown, true)
      }
    }
  }
}
