import React, { RefObject } from 'react'

import Toggle from '../../utils/toggle/Toggle'

import { Button, ButtonProps } from './Button'

class ButtonToggle extends React.Component<ButtonProps, {}> {
  constructor(props: any) {
    super(props)
    this.trigger = React.createRef()
  }

  componentDidMount() {
    this.instance = new Toggle(this.trigger.current)
  }

  componentWillUnmount() {
    this.instance.destroy()
  }

  private trigger: RefObject<React.ReactNode>

  private instance: any

  render() {
    const { children, ...other } = this.props
    return (
      <Button elemRef={this.trigger} {...other}>
        {children}
      </Button>
    )
  }
}

export { ButtonToggle }
