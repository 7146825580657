import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'img'

interface ImageProps {
  src: string
  alt?: string
  className?: string
  fluid?: boolean
  rounded?: boolean
  circle?: boolean
}

type Props = React.HTMLAttributes<HTMLImageElement> & ImageProps

const Image: React.SFC<Props> = ({ src, alt, className, fluid, rounded, circle, ...other }) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--fluid`]: fluid,
      [`${CLASS_ROOT}--rounded`]: rounded,
      [`${CLASS_ROOT}--circle`]: circle,
    },
    className
  )

  return <img className={classes} src={src} alt={alt} {...other} />
}

export { Image }
