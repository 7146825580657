import { SCOPES } from '../../../config/constants'
import { appStore } from '../../AppStore'

export const getFaqsPageHref = () => {
  const userCurrentRole = appStore.currentRole

  if (userCurrentRole === SCOPES.Client) {
    return '/client/faqs'
  }

  if (userCurrentRole === SCOPES.Holder) {
    return '/end-user/faqs'
  }

  if (userCurrentRole === SCOPES.Public) {
    return '/faqs'
  }

  return '/faqs'
}
