// TODO: change anys to something more meaningful with martin's consultation

import React from 'react'
import cx from 'classnames'
import { TabsStatic } from './Tabs.static'
import { keyCodes } from '../utils'

interface TabsOwnProps {
  /** Index of active tab, if not specified, first tab is selected. */
  activeTabIndex: number
  /** Additional classes for tabs header. */
  classesNav?: string
  /** Additional classes for tabs content. */
  classesTabs?: string
  /** On tab change callback function. */
  onChange?: (activeTabIndex: number, oldActiveTab: any, e: any) => void
  /** Hide tab cards */
  hideTabsSelection?: boolean
  children?: any
}

type TabsProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & TabsOwnProps

interface TabsState {
  activeTabIndex: number
}

class Tabs extends React.Component<TabsProps, TabsState> {
  tabListRef = React.createRef<HTMLUListElement>()

  static defaultProps = {
    activeTabIndex: 0,
    onChange: (_activeTabIndex: number, _oldActiveTab: any, _e: any) => {},
  }

  state: TabsState = {
    activeTabIndex: this.props.activeTabIndex,
  }

  componentDidMount() {
    if (this.tabListRef.current) {
      const tabs = Array.from(this.tabListRef.current.querySelectorAll('[role="tab"]'))
      this.tabs = tabs.map(tab => new TabsStatic(tab))
    }
  }

  componentWillReceiveProps(props: TabsProps) {
    if (props.activeTabIndex) {
      this.setState({
        activeTabIndex: props.activeTabIndex,
      })
    }
  }

  componentWillUnmount() {
    this.tabs?.forEach((tab: any) => {
      tab.destroy()
    })
  }

  getTabs = () => {
    const { children } = this.props

    return React.Children.toArray(children)
      .filter(i => i != null)
      .map((child: any, i: number) => {
        const activeProps = {
          onClick: (e: any) =>
            !child.props.tab.props.isDisabled ? this.handleTabClick(i, e) : () => {},
          onKeyDown: (e: any) =>
            !child.props.tab.props.isDisabled && e.keyCode === keyCodes.ENTER
              ? this.handleTabClick(i, e)
              : () => {},
        }

        return React.cloneElement(child.props.tab, {
          key: child.props.tab.props.id,
          isActive: this.state.activeTabIndex === i,
          ...(!(process.env.REACT_APP_TYPE === 'static') && activeProps),
        })
      })
  }

  getPanels = () => {
    if (this.props.children) {
      return React.Children.toArray(this.props.children)
        .filter(i => i != null)
        .map((tabPanel: any, index: number) =>
          React.cloneElement(tabPanel, {
            key: `tabpanel-${tabPanel.props.tab.props.id}`,
            isActive: index === this.state.activeTabIndex,
          })
        )
    }
  }

  handleTabClick = (newIndex: number, e: any) => {
    // e.preventDefault()
    if (this.props.onChange) {
      this.props.onChange(newIndex, this.state.activeTabIndex, e)
    }

    this.setState({
      activeTabIndex: newIndex,
    })
  }

  tabs: any

  // TODO: move this to Tabs.static.js as static method with el as param
  // handleTabsHeaderScroll = () => {
  //   const el = this.tabsHeaderRef;
  //   let scrollPosition;
  //
  //   if (el.offsetWidth === el.scrollWidth) {
  //     scrollPosition = null;
  //   } else if (el.scrollLeft === 0) {
  //     scrollPosition = 'left';
  //   } else if (el.scrollWidth - el.offsetWidth <= el.scrollLeft + 1) {
  //     scrollPosition = 'right';
  //   } else {
  //     scrollPosition = 'middle';
  //   }
  //
  //   this.setState({
  //     headerScrollPosition: scrollPosition,
  //   });
  // };

  render() {
    const {
      activeTabIndex, // eslint-disable-line
      className,
      classesNav,
      classesTabs,
      onChange,
      hideTabsSelection,
      ...other
    } = this.props

    const tabNavClasses = cx(
      'tab-list',
      // {
      //   [`${CLASS_ROOT}_header-scroll-${this.state.headerScrollPosition}`]: this
      //     .state.headerScrollPosition,
      // },
      classesNav
    )

    const tabClasses = cx('tab-panels', classesTabs)

    return [
      !hideTabsSelection ? (
        <div
          key="tablist"
          className={tabNavClasses}
          // onScroll={this.handleTabsHeaderScroll}
          {...other}>
          <ul className="tab-list__list" role="tablist" ref={this.tabListRef}>
            {this.getTabs()}
          </ul>
        </div>
      ) : (
        <></>
      ),
      <article key="tabpanels" className={tabClasses}>
        {this.getPanels()}
      </article>,
    ]
  }
}

export { Tabs }
