import React, { useContext } from 'react'
import cx from 'classnames'
import { Bar } from '../bar/Bar'
import { BarItem } from '../bar/BarItem'
import { Icon } from '../icon/Icon'
import { ButtonLink } from '../button/ButtonLink'
import { ReachDialogCtx } from './Dialog'

const CLASS_ROOT = 'dialog__header'

interface DialogHeaderOwnProps {
  className?: string
  title?: React.ReactNode
  closeButton?: React.ReactNode
  children?: React.ReactNode
  close?: (event: any) => void
  hideCloseBtn?: boolean
}

type DialogHeaderProps = React.HTMLAttributes<HTMLDivElement> & DialogHeaderOwnProps

const DialogHeader: React.SFC<DialogHeaderProps> = ({
  className,
  children,
  title,
  closeButton,
  close,
  hideCloseBtn,
  ...other
}) => {
  const ctx = useContext(ReachDialogCtx)

  const _closeButton = closeButton || (
    <ButtonLink
      equal
      data-a11y-dialog-hide
      onClick={close || (ctx && ctx.close)}
      aria-label="Close the dialog"
      className="text-color-grey"
      size="tiny">
      <Icon name="close" size="medium" />
    </ButtonLink>
  )
  const classes = cx(CLASS_ROOT, className)
  const cardTitle = typeof title === 'string' ? <h2 className="h5">{title}</h2> : title

  const headerBar = (
    <Bar>
      <BarItem isFilling>{cardTitle}</BarItem>
      {!hideCloseBtn && _closeButton && <BarItem>{_closeButton}</BarItem>}
    </Bar>
  )

  return (
    <div className={classes} {...other}>
      {headerBar}
      {children}
    </div>
  )
}

export { DialogHeader }
