import React, { useRef } from 'react'
import cx from 'classnames'
import { Icon, IconProps } from '../icon/Icon'
import { ButtonLinkSecondary } from '../button/ButtonLinkSecondary'
import { Bar } from '../bar/Bar'
import { BarItem } from '../bar/BarItem'
import { useSessionClose } from './useSessionClose'

const CLASS_ROOT = 'notice'

interface NoticeProps {
  /** Icon name */
  icon?: string
  /** Custom rendering function for icon */
  renderIcon?: Function
  /** Enable close button */
  closable?: boolean
  /** Custom close icon size */
  closeSize?: IconProps['size']
  /** Custom rendering function for close icon */
  renderClose?: Function
  /** Type of notice */
  type?: 'promo' | 'tip' | 'alert'
  id: string
}

type Props = React.HTMLAttributes<HTMLDivElement> & NoticeProps

const Notice: React.SFC<Props> = props => {
  const {
    className,
    children,
    icon,
    renderIcon,
    closable,
    closeSize = 'medium',
    renderClose,
    type,
    id,
    ...other
  } = props

  const closeButton = useRef<HTMLButtonElement>(null)
  const opened = useSessionClose(closeButton, id)

  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${type}`]: type,
    },
    className
  )

  const noticeIcon =
    (renderIcon && renderIcon(props)) ||
    (icon && <Icon name={icon} size="medium" className="text-vam" />)

  return opened ? (
    <div data-notice className={classes} {...other}>
      <Bar className="mb-none">
        {noticeIcon && <BarItem>{noticeIcon}</BarItem>}
        <BarItem isFilling>{children}</BarItem>
        {closable && (
          <BarItem>
            <ButtonLinkSecondary equal size="tiny" elemRef={closeButton} data-notice-close>
              <Icon name="close" size={closeSize} />
            </ButtonLinkSecondary>
          </BarItem>
        )}
      </Bar>
    </div>
  ) : null
}

export { Notice }
