import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'dropdown'

export interface DropdownOwnProps {
  /** Disable close dropdown on click insie. */
  dropdownInteractive?: boolean

  /** Width of dropdown element */
  width?: 'wide' | 'wider' | undefined

  /** Show arrow */
  hasArrow?: boolean

  className?: string

  elemRef?: any

  id?: string
}

const Dropdown: React.SFC<DropdownOwnProps> = ({
  className = '',
  hasArrow,
  width,
  children,
  dropdownInteractive,
  elemRef,
  id,
}) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--nowrap`]: dropdownInteractive,
      [`${CLASS_ROOT}--space`]: dropdownInteractive,
      [`${CLASS_ROOT}--${width}`]: width,
      [`${CLASS_ROOT}--arrow`]: hasArrow,
    },
    className
  )

  return (
    <div
      role="menu"
      className={classes}
      ref={elemRef}
      id={id}
      data-dropdown=""
      data-dropdown-interactive={dropdownInteractive}>
      {hasArrow && (
        <div className={`${CLASS_ROOT}__arrow`}>
          <div className={`${CLASS_ROOT}__arrow__shadow`} />
        </div>
      )}
      <div className="dropdown__content">{children}</div>
    </div>
  )
}

export { Dropdown }
