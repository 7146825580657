// ---------------------------------------------------
// Toggle
// module for handling toggle actions
import toggle from './ToggleUtil'

export const TRIGGER_EVT = 'toggleTrigger'
export const TOGGLE_EVT = 'toggle'

const defaultConfig = {
  activeClass: 'is-active',
  settingsAttr: 'data-toggle',
}

class Toggle {
  element: any

  config: any & {
    onToggleChange?: () => void
  }

  settings: any

  targets: any[]

  constructor(element: any, config: any = {}) {
    this.element = element
    this.config = { ...defaultConfig, ...config }

    this.settings = {}
    this.targets = []

    this.handleClick = this.handleClick.bind(this)

    this.init()

    return this
  }

  doToggle(props: any) {
    const { dispatchEvent = true } = props
    let { element, attribute, value } = props

    const returnProps = { ...props }
    const eventDetail = { trigger: this.element, element }

    if (attribute === 'icon') {
      attribute = 'xlink:href'
      element =
        element.querySelector('[data-toggle-icon-target] use') || element.querySelector('use')
    }

    if (attribute === 'text') {
      element = element.querySelector('[data-toggle-text-target]') || element
    }

    if (typeof value === 'undefined') {
      value = false
    }

    returnProps.value = toggle({
      element,
      attribute,
      value,
      dispatchEvent,
      eventDetail,
    })

    return returnProps
  }

  init() {
    this.settings = JSON.parse(this.element.getAttribute(this.config.settingsAttr))

    this.targets = this.getTargets()

    this.element.addEventListener('click', this.handleClick)

    return this.targets
  }

  handleClick() {
    this.targets = this.targets.map(target => this.doToggle(target))

    this.element.dispatchEvent(new CustomEvent(TRIGGER_EVT, { bubbles: true }))

    if (this.config.onToggleChange) {
      this.config.onToggleChange()
    }
  }

  destroy() {
    this.element.removeEventListener('click', this.handleClick)
    this.settings = []
    this.targets = []
  }

  update() {
    this.removeNonExistentTargets()
    this.addNewTargets()
  }

  static getTargetElementsFromString(targetData: any) {
    const { target, trigger, ...other } = targetData

    if (target === 'self') {
      return [trigger]
    }

    if (target === 'parent') {
      const { parentHop = 1 } = other

      let parentTarget = trigger
      for (let i = 0; i < parentHop; i += 1) {
        parentTarget = parentTarget.parentNode
      }

      return [parentTarget]
    }

    if (target === 'previous') {
      return [trigger.previousElementSibling]
    }

    if (target === 'next') {
      return [trigger.nextElementSibling]
    }

    if (typeof target === 'string') {
      return Array.from(document.querySelectorAll(target))
    }

    // add log why we endup here
    return target
  }

  getTargets() {
    // this deep nesting looks ugly and should be refactored. interestingly, spread operators do not work here, but concat does :/
    return [].concat(
      ...this.settings.map((setting: any) => {
        const targets = setting.target.split(',')

        return [].concat(
          ...targets.map((target: any) => {
            // loop over all target strings
            const targetElements = Toggle.getTargetElementsFromString({
              target,
              trigger: this.element,
              attribute: setting.attribute,
              value: setting.value,
            })

            return [].concat(
              // loop over all found targets. we need this to use querySelectorAll (do we need querySelectorAll?)
              ...targetElements.map((targetElement: any) => ({
                ...setting,
                element: targetElement,
              }))
            )
          })
        )
      })
    )
  }

  removeNonExistentTargets() {
    this.targets = this.targets.filter(target => document.contains(target.element))
  }

  addNewTargets() {
    const allTargets = this.getTargets() as any[]
    const newTargets = allTargets.filter(
      newTarget =>
        !this.targets.find(
          target => target.element === newTarget.element && target.action === newTarget.action
        )
    )

    this.targets = [...this.targets, ...newTargets]
  }
}

export default Toggle
