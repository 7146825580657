import React from 'react'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { BarItem } from '../bar/BarItem'

const CLASS_ROOT = 'main-nav'

interface MainNavItemProps {
  type?: 'main' | 'user'

  /** Link for the item */
  href?: string

  /** Active item indicator */
  isActive?: boolean

  logoTop?: React.ReactElement
}

type Props = React.HTMLAttributes<HTMLAnchorElement> & MainNavItemProps

const MainNavItem = ({ children, href, type, isActive, logoTop, className, ...other }: Props) => {
  const router = useRouter()
  const classes = cx(
    `${CLASS_ROOT}__item`,
    {
      'is-active': isActive ?? (router ? router.pathname === href : false),
    },
    className
  )

  return (
    <BarItem tag="li" className={classes}>
      {href ? (
        <Link href={href}>
          <a className={`${CLASS_ROOT}__link`} {...other} style={{ position: 'relative' }}>
            <span style={{ position: 'absolute', top: 0, right: 0 }}>{logoTop}</span>
            {children}
          </a>
        </Link>
      ) : (
        children
      )}
    </BarItem>
  )
}

export { MainNavItem }
