import React from 'react'
import cx from 'classnames'
import { DialogProps, Dialog } from '@reach/dialog'

const CLASS_ROOT = 'dialog'

interface TDialogProps {
  className?: string
  children?: React.ReactNode
  size?: 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'
  fixed?: boolean
}

export const ReachDialogCtx = React.createContext<{ close: () => void }>(null as any)

export const ReachDialog: React.SFC<Omit<DialogProps, 'size'> & TDialogProps> = ({
  className,
  children,
  size,
  fixed = true,
  onDismiss = () => ({}),
  ...other
}) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${size}`]: size,
      [`${CLASS_ROOT}--fixed`]: fixed,
    },
    className
  )

  return (
    <Dialog className={classes} onDismiss={onDismiss} aria-label="dialog" {...other}>
      <ReachDialogCtx.Provider value={{ close: onDismiss }}>{children}</ReachDialogCtx.Provider>
    </Dialog>
  )
}
