import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'card__bg'

interface CardBgProps {
  className?: string
  url: string
}

type Props = React.HTMLAttributes<HTMLDivElement> & CardBgProps

const CardBg: React.SFC<Props> = ({ className, url, ...other }) => {
  const classes = cx(CLASS_ROOT, className)

  return (
    <div className={classes} {...other}>
      <img src={url} alt="" aria-hidden="true" />
    </div>
  )
}

export { CardBg }
