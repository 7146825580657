import React from 'react'
import cx from 'classnames'

import { Label } from '../../label/Label'
import { Message, MessageOwnProps } from '../../message/Message'

interface RadioCheckGroupOwnProps {
  /** Array of messages for group shape [{ type: 'help', text: 'Text help' }, {type: 'error', text: 'Text error'}] */
  messages?: {
    type?: MessageOwnProps['type']
    text: React.ReactNode
    icon?: string
    iconSpritePath?: string
  }[]

  /** Label text. */
  label?: string

  /** Custom label renderer. Passes props as function parameter. */
  renderLabel?: Function

  /** Help text. */
  help?: string

  /** Custom help renderer. Passes props as function parameter. */
  renderHelp?: Function

  /** Nested group */
  isNested?: boolean

  /** Form control (wrapper) class */
  formControlClass?: string
}

type Props = React.HTMLAttributes<HTMLDivElement> & RadioCheckGroupOwnProps

const RadioCheckGroup: React.SFC<Props> = props => {
  const {
    children,
    className,
    messages = [],
    label,
    renderLabel,
    isNested,
    formControlClass,
    id,
    ...other
  } = props

  const classes = cx(
    'radiocheck-group',
    {
      'radiocheck-group--nested': isNested,
    },
    className
  )

  const formControlClasses = cx('form-control', 'form-control--group', formControlClass)

  const error = messages.some(m => m.type === 'error')

  const groupLabel =
    (renderLabel && renderLabel(props)) ||
    (label && (
      <Label id={id} isError={error}>
        {label}
      </Label>
    ))

  const messagesOutput = messages.map((m, idx) => (
    <Message key={idx} type={m.type} icon={m.icon}>
      {m.text}
    </Message>
  ))

  return (
    <div className={formControlClasses}>
      <div className={classes} {...other}>
        {groupLabel}
        {children}
        {messagesOutput}
      </div>
    </div>
  )
}

export { RadioCheckGroup }
