import React, { ReactNode, useRef, useEffect } from 'react'
import cx from 'classnames'
import { withUniqueId } from 'react-maria'

import { createPortal } from 'react-dom'
import { Button, ButtonProps } from './Button'
import { Dropdown, DropdownOwnProps } from '../dropdown/Dropdown'
import { Icon, IconOwnProps } from '../icon/Icon'

import ToggleStatic from '../../utils/toggle/Toggle'
import DropdownStatic from '../dropdown/Dropdown.static'
import { clientOnly } from '../../utils/hoc'
import { useWillUnmount } from '../../utils/hooks/useWillUnmount'

const CLASS_ROOT = 'btn-dropdown'

interface ToggleButtonOwnProps {
  targetId: string
  iconToggle: string
}

type ToggleButtonProps = ButtonProps & ToggleButtonOwnProps

const ToggleButton: React.SFC<ToggleButtonProps> = ({
  children,
  targetId,
  iconToggle,
  ...other
}) => (
  <Button
    data-btn-dropdown-trigger=""
    data-toggle={JSON.stringify([
      {
        target: 'self',
        attribute: 'icon',
        value: `#${iconToggle}`,
      },
      { target: 'self', attribute: 'aria-expanded' },
      {
        target: `#${targetId}`,
        attribute: 'class',
        value: 'is-active',
      },
    ])}
    {...other}>
    {children}
  </Button>
)

interface ButtonDropdownOwnProps {
  type?: ButtonProps['type']
  equal?: ButtonProps['equal']
  space?: ButtonProps['space']
  size?: ButtonProps['size']
  content?: ReactNode
  dropdownInteractive?: boolean
  icon?: boolean
  iconName?: string
  iconSize?: IconOwnProps['size']
  iconRight?: boolean
  iconToggle?: string
  split?: boolean
  /** <a href="https://popper.js.org/tooltip-documentation.html">Popper.js</a> options object */
  popperOptions?: object
  /** Unique ID (generated automatically) */
  // id?: string
  isDisabled?: boolean
  isLoading?: ButtonProps['isLoading']
  loaderProps?: ButtonProps['loaderProps']
  dropdownWidth?: DropdownOwnProps['width']
  dropdownArrow?: boolean
  spaceBetween?: boolean
  dropdownClassName?: string
  onToggleChange?: () => void
  getDropdownStaticRef?: (ref: any) => void
}

export type ButtonDropdownProps = React.HTMLAttributes<HTMLDivElement> & ButtonDropdownOwnProps

// class ButtonDropdownInner extends React.Component<ButtonDropdownProps> {
//   anchorRef = React.createRef<HTMLDivElement>()

//   triggerRef = React.createRef()

//   dropdownRef = React.createRef()

//   componentDidMount() {
//     this.toggleButton = new ToggleStatic(this.triggerRef.current)
//     this.dropdown = new DropdownStatic(this.dropdownRef.current, {
//       anchorElement: this.anchorRef.current,
//       isStatic: false,
//     })
//   }

//   componentWillUnmount() {
//     this.dropdown.destroy()
//   }

//   toggleButton: any

//   dropdown: any

//   render() {
//     const {
//       children,
//       className,
//       content,
//       dropdownInteractive,
//       icon,
//       iconName = 'chevron-down',
//       iconSize = 'small',
//       iconRight = true,
//       iconToggle = 'chevron-up',
//       split,
//       type,
//       equal,
//       space,
//       popperOptions,
//       id,
//       size,
//       isDisabled,
//       dropdownWidth,
//       dropdownArrow,
//       spaceBetween,
//       ...other
//     } = this.props

//     delete (other as any).getMariaIdTools

//     const classes = cx(
//       CLASS_ROOT,
//       {
//         [`${CLASS_ROOT}--split`]: split,
//       },
//       className
//     )

//     let buttonDropdownIcon = null
//     if (icon) {
//       buttonDropdownIcon = (
//         <Icon
//           data-toggle-icon-target
//           name={iconName}
//           size={iconSize}
//           className={iconRight ? 'icon--right' : ''}
//         />
//       )
//     }

//     let buttonsToRender = null
//     if (split) {
//       buttonsToRender = (
//         <>
//           <Button type={type} size={size} isDisabled={isDisabled}>
//             {content}
//           </Button>
//           <ToggleButton
//             targetId={id!}
//             elemRef={this.triggerRef}
//             iconToggle={iconToggle}
//             type={type}
//             size={size}
//             isDisabled={isDisabled}>
//             {buttonDropdownIcon}
//           </ToggleButton>
//         </>
//       )
//     } else {
//       buttonsToRender = (
//         <ToggleButton
//           targetId={id!}
//           elemRef={this.triggerRef}
//           iconToggle={iconToggle}
//           type={type}
//           equal={equal}
//           space={space}
//           size={size}
//           spaceBetween={spaceBetween}
//           isDisabled={isDisabled}>
//           {content}
//           {buttonDropdownIcon}
//         </ToggleButton>
//       )
//     }

//     const DropdownCmp = (
//       <Dropdown
//         // key="dropdown-elem"
//         elemRef={this.dropdownRef}
//         id={id}
//         dropdownInteractive={dropdownInteractive}
//         width={dropdownWidth}
//         hasArrow={dropdownArrow}
//         data-popper-options={JSON.stringify(popperOptions)}>
//         {children}
//       </Dropdown>
//     )

//     return (
//       <>
//         <div data-dropdown-anchor={id} ref={this.anchorRef} className={classes} {...other}>
//           {buttonsToRender}
//         </div>

//         {typeof document === 'undefined'
//           ? DropdownCmp
//           : createPortal(DropdownCmp, document.querySelector('#root-dropdowns')!)}
//       </>
//     )
//   }
// }

const ButtonDropdownInner2 = (props: ButtonDropdownProps) => {
  const {
    children,
    className,
    content,
    dropdownInteractive,
    icon,
    iconName = 'chevron-down',
    iconSize = 'small',
    iconRight = true,
    iconToggle = 'chevron-up',
    split,
    type,
    equal,
    space,
    popperOptions,
    id,
    size,
    isDisabled,
    isLoading,
    loaderProps,
    dropdownWidth,
    dropdownArrow,
    spaceBetween,
    getDropdownStaticRef,
    dropdownClassName,
    onToggleChange,
    ...other
  } = props

  const anchorRef = useRef<any>()
  const triggerRef = useRef()
  const dropdownRef = useRef()
  const toggleButtonRef = useRef<any>()
  const dropdownInstanceRef = useRef<any>()

  useEffect(() => {
    toggleButtonRef.current = new ToggleStatic(triggerRef.current, { onToggleChange })
    dropdownInstanceRef.current = new DropdownStatic(dropdownRef.current, {
      anchorElement: anchorRef.current,
      isStatic: false,
    })

    if (getDropdownStaticRef)
      getDropdownStaticRef(
        // toggleButtonRef.current
        dropdownInstanceRef.current
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useWillUnmount(() => {
    dropdownInstanceRef.current.destroy()
    toggleButtonRef.current.destroy()
  })

  delete (other as any).getMariaIdTools

  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--split`]: split,
    },
    className
  )

  let buttonDropdownIcon = null
  if (icon) {
    buttonDropdownIcon = (
      <Icon
        data-toggle-icon-target
        name={iconName}
        size={iconSize}
        className={iconRight ? 'icon--right' : ''}
      />
    )
  }

  let buttonsToRender = null
  if (split) {
    buttonsToRender = (
      <>
        <Button
          type={type}
          size={size}
          isDisabled={isDisabled}
          isLoading={isLoading}
          loaderProps={loaderProps}>
          {content}
        </Button>
        <ToggleButton
          targetId={id!}
          elemRef={triggerRef}
          iconToggle={iconToggle}
          type={type}
          size={size}
          isDisabled={isDisabled}>
          {buttonDropdownIcon}
        </ToggleButton>
      </>
    )
  } else {
    buttonsToRender = (
      <ToggleButton
        targetId={id!}
        elemRef={triggerRef}
        iconToggle={iconToggle}
        type={type}
        equal={equal}
        space={space}
        size={size}
        spaceBetween={spaceBetween}
        isDisabled={isDisabled}
        isLoading={isLoading}
        loaderProps={loaderProps}>
        {content}
        {buttonDropdownIcon}
      </ToggleButton>
    )
  }

  const DropdownCmp = (
    <Dropdown
      // key="dropdown-elem"
      elemRef={dropdownRef}
      id={id}
      dropdownInteractive={dropdownInteractive}
      width={dropdownWidth}
      hasArrow={dropdownArrow}
      data-popper-options={JSON.stringify(popperOptions)}
      className={dropdownClassName}>
      {children}
    </Dropdown>
  )

  return (
    <>
      <div data-dropdown-anchor={id} ref={anchorRef} className={classes} {...other}>
        {buttonsToRender}
      </div>

      {typeof document === 'undefined'
        ? DropdownCmp
        : createPortal(DropdownCmp, document.querySelector('#root-dropdowns')!)}
    </>
  )
}

export const ButtonDropdown = clientOnly(withUniqueId()(ButtonDropdownInner2))
