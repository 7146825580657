import React, { useState, useEffect, ReactNode, KeyboardEvent } from 'react'
import cx from 'classnames'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { Addons } from './addons/Addons'
import { Label } from '../label/Label'
import { MessageOwnProps, Message } from '../message/Message'
import { ReachTooltip } from '../..'

const CLASS_ROOT = 'input'

/**
 * if you only want to highlight input field without showing message
 * field.setError(NO_MESSAGE_ERROR)
 */
export const NO_MESSAGE_ERROR = 'NO_MESSAGE_ERROR'

export interface InputOwnProps {
  inputRef?: React.MutableRefObject<HTMLInputElement | null>

  leftAddons?: React.ReactNode
  rightAddons?: React.ReactNode

  /** Array of messages for input shape [{ type: 'help', text: 'Text help' }, {type: 'error', text: 'Text error'}] */
  messages?: {
    type?: MessageOwnProps['type']
    text: React.ReactNode
    icon?: string
    iconSpritePath?: string
  }[]

  /** Html id attribute. */
  id?: string

  /** Active state. */
  isActive?: boolean

  /** Disabled state. */
  isDisabled?: boolean

  /** Readonly state. */
  isReadonly?: boolean

  /** Label text. */
  label?: string | object | ReactNode

  /** Custom label renderer. Passes props as function parameter. */
  renderLabel?: (props: InputProps) => React.ReactNode

  /** Label text. */
  labelTooltip?: string

  /** Description text displayed under label. */
  labelDescription?: string

  /** Input tooltip text. */
  inputTooltip?: string

  /** Inline label */
  labelInline?: boolean

  /** Multiline input */
  multiline?: boolean

  /** Html name attribute. */
  name?: string

  /** Input value max length */
  maxLength?: number

  /** Value change callback function. */
  // onChange?: Function

  /** Placeholder text */
  placeholder?: string

  /** Size of element. */
  inputSize?: 'small' | 'large' | 'huge'

  /** Input html type. */
  type?: string

  /** Input value. */
  value?: string

  /** Classnames applied to the form control. */
  formControlClass?: string

  /** Error state */
  error?: boolean

  /** Required field */
  isRequired?: boolean

  isLoading?: boolean

  withPasswordToggler?: boolean

  rows?: number

  onDidMount?: () => void

  onWillUnmount?: () => void
}

export type InputProps = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> &
  InputOwnProps

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & InputOwnProps

export const Input: React.SFC<InputProps> = (props: InputProps) => {
  const [type, setType] = useState(props.type || 'text')

  const {
    inputRef,
    children,
    className,
    messages = [],
    id,
    isActive, // ?
    isDisabled,
    isReadonly,
    label,
    labelDescription,
    labelInline,
    leftAddons,
    multiline,
    placeholder,
    renderLabel,
    labelTooltip,
    inputTooltip,
    rightAddons,
    inputSize,
    type: originalType,
    value,
    maxLength,
    formControlClass,
    error,
    isRequired,
    isLoading,
    onChange,
    withPasswordToggler,
    onDidMount,
    onWillUnmount,
    ...other
  } = props

  if (label) {
    // eslint-disable-next-line no-console
    console.assert(id, 'If label is set, also id has to be')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(e)
  }

  const isError = error || messages.some(m => m.type === 'error')

  const classes = cx(
    CLASS_ROOT,
    {
      'is-active': isActive,
      'is-error': isError,
      'is-loading': isLoading,
      'with-eye': withPasswordToggler,
      'with-left-addon': leftAddons,
      'with-right-addon': rightAddons,
      [`${CLASS_ROOT}--${inputSize}`]: inputSize,
    },
    className
  )

  const formControlClasses = cx(
    'form-control',
    `form-control--${multiline ? 'textarea' : 'input'}`,
    {
      'form-control--inline': labelInline,
    },
    formControlClass
  )

  // set name from id if name is not specfied
  let { name } = other
  name = name && id

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (maxLength && e.currentTarget.value.length >= maxLength) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const elementProps: React.InputHTMLAttributes<HTMLInputElement> &
    React.ClassAttributes<HTMLInputElement> = {
    // TODO:
    ref: inputRef,
    className: classes,
    id,
    name,
    value,
    type,
    disabled: isDisabled,
    readOnly: isReadonly,
    placeholder,
    onChange: handleChange,
    onKeyPress: handleKeyPress,
    // required: isRequired,
    ...other,
  }
  // const Element = multiline ? 'textarea' : 'input'

  const inputLabel =
    (renderLabel && renderLabel(props)) ||
    (label && (
      <>
        <Label id={id} size={inputSize === 'huge' ? 'large' : inputSize} isError={isError}>
          {label}
          {labelTooltip && (
            <ReachTooltip ariaLabel="" label={labelTooltip}>
              <span
                style={{
                  display: 'inline-flex',
                  position: 'relative',
                  marginLeft: 2,
                }}>
                <img width="15" height="15" src="/static/img/info.png" alt="info" />
              </span>
            </ReachTooltip>
          )}

          {isRequired && <span className="form-label__required">&nbsp;*</span>}
        </Label>
      </>
    ))

  const inputElement = multiline
    ? React.createElement('textarea', elementProps)
    : React.createElement('input', elementProps)

  const messagesOutput = messages
    .filter(m => m.text !== NO_MESSAGE_ERROR)
    .map((m, idx) => (
      <Message key={idx} type={m.type} icon={m.icon}>
        {m.text}
      </Message>
    ))

  const togglePassword = () => {
    if (type === 'password') {
      setType('text')
    } else if (type === 'text') {
      setType('password')
    }
  }

  const inputElementWrapped = (
    <div className="input-wrapper">
      {isLoading && <div className="input-loader" />}
      {withPasswordToggler &&
        (type === 'password' ? (
          <IoMdEye onClick={togglePassword} className="toggle-password-visibility" />
        ) : (
          <IoMdEyeOff onClick={togglePassword} className="toggle-password-visibility" />
        ))}
      {inputElement}
    </div>
  )

  useEffect(() => {
    if (onDidMount) onDidMount()
    return () => {
      if (onWillUnmount) onWillUnmount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inputElementRender = inputTooltip ? (
    <ReachTooltip ariaLabel="" label={inputTooltip}>
      {inputElementWrapped}
    </ReachTooltip>
  ) : (
    inputElementWrapped
  )

  return (
    <div className={formControlClasses}>
      {inputLabel}
      {labelDescription && (
        <div className="mb-smallish">
          <span className="text-small text-color-grey-600">{labelDescription}</span>
        </div>
      )}

      {leftAddons || rightAddons ? (
        <Addons left={leftAddons} right={rightAddons} size={inputSize}>
          {inputElementWrapped}
        </Addons>
      ) : (
        inputElementRender
      )}
      {messagesOutput}
    </div>
  )
}

export const TextArea: React.SFC<TextAreaProps> = (props: TextAreaProps) => {
  const { children, ..._props } = props
  return React.createElement(Input, { ..._props, multiline: true } as any, children)
}
