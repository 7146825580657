import React from 'react'
import cx from 'classnames'

interface BarBreakProps {
  /** Html tag in which the bar rendered. */
  tag?: keyof React.ReactHTML

  className?: string
}

const CLASS_ROOT = 'bar__break'

const BarBreak = ({ className, tag = 'div', ...other }: BarBreakProps) => {
  const Tag = tag
  const classes = cx(CLASS_ROOT, className)

  return React.createElement(Tag, { className: classes, ...other })
}

export { BarBreak }
