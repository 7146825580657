// ---------------------------------------------------
// Tabs
// module for handling tabs

import { getSiblings } from '../utils'

interface TabsStaticConfig {
  tabSelector: string
  activeClass: string
  navContainerSelector: string
  navItemSelector: string
  tabPanesContainerSelector: string
  tabPanelSelector: string
  scrollDelay: string
}

const defaultConfig: TabsStaticConfig = {
  tabSelector: '[role="tab"]',
  activeClass: 'is-active',
  navContainerSelector: '.tab-list__list',
  navItemSelector: '.tab-list__item',
  tabPanesContainerSelector: '.tab-panels',
  tabPanelSelector: '.tab-panels__panel',
  scrollDelay: '300',
}

// selector = '[role="tab"]'
class TabsStatic {
  element: HTMLElement

  config: TabsStaticConfig

  tabs: HTMLElement[] = []

  constructor(element: any, config: TabsStaticConfig = defaultConfig) {
    this.element = element
    this.config = { ...defaultConfig, ...config }

    TabsStatic.onClick = TabsStatic.onClick.bind(this)
    TabsStatic.toggleTab = TabsStatic.toggleTab.bind(this)
    TabsStatic.toggleTabList = TabsStatic.toggleTabList.bind(this)

    this.init()

    return this
  }

  init() {
    this.element.addEventListener('click', TabsStatic.onClick)

    this.tabs = Array.from(document.querySelectorAll(this.config.tabPanelSelector))

    this.openTabOnLoad()
  }

  destroy() {
    this.element.removeEventListener('click', TabsStatic.onClick)
  }

  update() {
    this.destroy()
    this.init()
  }

  // TODO: bring openTab on load based on location.href
  openTabOnLoad() {
    const hash = window.location.hash.slice(1)
    if (hash) {
      for (let i = 0, l = this.tabs.length; i < l; i += 1) {
        if (this.tabs[i].getAttribute('id') === hash) {
          // if (this.tabs[i].closest('.tabs').hasAttribute('data-tab-load')) {
          TabsStatic.toggleTab(this.tabs[i])
          // this._scrollToTab(this.tabs[i]);
          // }
        }
      }
    }
  }

  // _scrollToTab(el) {
  //   const trigger = this._findTrigger(el);
  //   const triggerContainer = trigger.closest('[role=tablist]');
  //   const offset = el.closest('.tabs').getAttribute('data-tab-load');
  //   let scrollTo = 0;
  //   if (!offset) {
  //     scrollTo = windowOffset(triggerContainer).y;
  //   } else if (!isNaN(offset)) {
  //     scrollTo = windowOffset(triggerContainer).y - offset;
  //   } else {
  //     const offsetContainer = document.querySelector(offset);
  //     if (offsetContainer) {
  //       scrollTo =
  //         windowOffset(triggerContainer).y - offsetContainer.offsetHeight;
  //     }
  //   }
  //   if (scrollTo) {
  //     setTimeout(() => {
  //       window.scroll(0, scrollTo);
  //     }, this.config.scrollDelay);
  //   }
  // }

  static onClick(e: any) {
    if (!e.target.hasAttribute('href')) {
      return
    }
    const tab = document.querySelector(e.target.getAttribute('href'))
    TabsStatic.toggleTab(tab)

    e.preventDefault()
  }

  static toggleTab(el: HTMLElement, config = defaultConfig) {
    const { activeClass, tabPanesContainerSelector = config.tabPanesContainerSelector } = config

    if (!el) {
      return
    }

    const container = el.closest(tabPanesContainerSelector)
    if (!container) {
      return
    }

    const siblings = getSiblings(el)

    if (!siblings) return

    siblings.forEach(sibling => {
      sibling.classList.remove(activeClass)
      sibling.setAttribute('aria-hidden', true)
    })

    el.classList.add(activeClass)
    el.setAttribute('aria-hidden', 'false')

    TabsStatic.toggleTabList(el)

    // TODO: what is this doing
    // if (el.parentNode.hasAttribute('data-tab-load')) {
    //   history.replaceState(undefined, undefined, `#${el.getAttribute('id')}`);
    // }
  }

  static toggleTabList(el: HTMLElement, config = defaultConfig) {
    const { navItemSelector, activeClass, tabSelector, navContainerSelector } = config

    const trigger = TabsStatic.findTrigger(el.getAttribute('id'))

    if (!trigger) {
      return
    }

    const triggerContainer = trigger.closest(navContainerSelector)

    if (!triggerContainer) {
      return
    }

    const currentItem =
      triggerContainer.querySelector(`${navItemSelector}.${activeClass} ${tabSelector}`) ||
      triggerContainer.querySelector(`${navItemSelector}.${activeClass}${tabSelector}`)

    if (trigger === currentItem) {
      return
    }

    if (currentItem) {
      const currentItemClosest = currentItem.closest(navItemSelector)
      if (currentItemClosest) {
        currentItemClosest.classList.remove(activeClass)
        currentItem.setAttribute('aria-selected', 'false')
      }
    }

    const triggerClosest = trigger.closest(navItemSelector)
    if (triggerClosest) {
      triggerClosest.classList.add(activeClass)
      trigger.setAttribute('aria-selected', 'true')
    }
  }

  static findTrigger(id: string | null) {
    return document.querySelector(`.tab[href="#${id}"]`)
  }
}

export { TabsStatic }
