import Popper from 'popper.js'

const defaultConfig = {
  rootContainerSelector: '#root-tooltips',
  popperOptions: {
    placement: 'top',
    removeOnDestroy: !true,
    modifiers: {
      arrow: {
        element: '.tooltip__arrow',
      },
    },
  },
}

export class TooltipStatic {
  element: HTMLElement | null

  trigger: HTMLElement | null

  instance!: Popper

  config: any

  constructor(element: HTMLElement, trigger: HTMLElement, config: object) {
    this.element = element
    this.trigger = trigger
    this.config = { ...defaultConfig, ...config }

    this.handleVisibility = this.handleVisibility.bind(this)

    this.init()

    return this
  }

  init() {
    if (!this.trigger) {
      this.trigger = document.querySelector(
        `[aria-describedby=${this.element!.getAttribute('id')}]`
      )
    }

    this.instance = new Popper(this.trigger!, this.element!, this.config.popperOptions)

    this.trigger!.addEventListener('mouseenter', this.handleVisibility, true)
    this.trigger!.addEventListener('mouseleave', this.handleVisibility)
    this.trigger!.addEventListener('focus', this.handleVisibility, true)
    this.trigger!.addEventListener('blur', this.handleVisibility)
  }

  handleVisibility(e: any) {
    if (e.type === 'mouseenter' || e.type === 'focus') {
      this.show()
    }

    if (e.type === 'blur') {
      this.hide()
    }

    if (e.type === 'mouseleave') {
      const shouldClose = this.shouldClose(e)

      if (shouldClose) {
        this.hide()
      }
    }
  }

  shouldClose(e: any) {
    const mouseLeftTo = e.relatedreference || e.toElement || e.relatedTarget

    const callback = (e2: any) => {
      const mouseLeftTo2 = e2.relatedreference || e2.toElement || e2.relatedTarget

      this.element!.removeEventListener('mouseleave', callback)

      if (!this.trigger!.contains(mouseLeftTo2)) {
        this.hide()
      }
    }

    if (this.element!.contains(mouseLeftTo)) {
      this.element!.addEventListener('mouseleave', callback)
      return false
    }

    return true
  }

  show() {
    this.element!.setAttribute('aria-hidden', 'false')
    this.update() // this fixes incorrect positioning. ¯\_(ツ)_/¯
  }

  hide() {
    this.element!.setAttribute('aria-hidden', 'true')
  }

  update() {
    this.instance.update()
  }

  destroy() {
    this.trigger!.removeEventListener('mouseenter', this.handleVisibility)
    this.trigger!.removeEventListener('mouseleave', this.handleVisibility)
    this.trigger!.removeEventListener('focus', this.handleVisibility)
    this.trigger!.removeEventListener('blur', this.handleVisibility)

    this.instance.destroy()
  }
}
