import { forEach } from 'lodash-es'

type StringOrNumber = string | number

export function axiosParamsToSequence(params: Record<string, StringOrNumber | StringOrNumber[]>) {
  const sequence: [string, string][] = []
  forEach(params, (value, key) => {
    if (Array.isArray(value)) {
      const foo = value.map(i => [key, String(i)]) as [string, string][]
      sequence.push(...foo)
    } else {
      sequence.push([key, String(value)])
    }
  })

  return sequence
}
