import React, { ReactNode, useEffect, useState } from 'react'

import { Trans } from '@lingui/macro'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { Bar, BarItem, ButtonLayout, ButtonLinkSecondary, ButtonPrimary, Container, Icon } from '..'

const getSessionStorageNoticeId = (notice: NoticeType) => {
  const id = notice.type === NoticeTypeEnum.hint ? `hint${notice.id}` : notice.id
  return `notice#${id}`
}

const isNoticeHidden = (notice: NoticeType) => {
  const noticeId = getSessionStorageNoticeId(notice)
  return JSON.parse(String(sessionStorage.getItem(noticeId))) === false
}

const hideNotice = (notice: NoticeType) => {
  const noticeId = getSessionStorageNoticeId(notice)
  return sessionStorage.setItem(noticeId, JSON.stringify(false))
}

export enum NoticeTypeEnum {
  'notice' = 'notice',
  'hint' = 'hint',
}

export enum NoticeContentTypeEnum {
  'tip' = 'tip',
  'alert' = 'alert',
}

export type NoticeType = {
  id: number
  renderContent: (options: { textColor: string }) => ReactNode
  contentType?: NoticeContentTypeEnum
  type?: NoticeTypeEnum | undefined
  filterData?: {
    productId?: string | number
  }
}

export const NoticeList = observer(
  (props: {
    notices: NoticeType[]
    filterBy?: {
      productId?: string | number
    }
  }) => {
    const [relevantNotices, setRelevantNotices] = useState<NoticeType[]>([])
    const [showAllHints, setShowAllHints] = useState(false)

    const hintLimit = 3

    //
    // HANDLE RELEVANT NOTICES
    //
    useEffect(() => {
      if (props.notices?.length) {
        let notices = [...props.notices]

        // FILTER BY ACTIVE PRODUCT
        if (props.filterBy?.productId !== undefined) {
          notices = notices.filter(
            notice =>
              notice.filterData?.productId === undefined ||
              notice.filterData?.productId === props.filterBy?.productId
          )
        }

        // FILTER BY VISIBLE NOTICES
        notices = notices.filter(notice => !isNoticeHidden(notice))

        // STORE RELEVANT NOTICES
        setRelevantNotices(notices)
      } else {
        setRelevantNotices([])
      }
    }, [props.notices, props.filterBy?.productId])

    const onNoticeClose = (notice: NoticeType) => {
      setRelevantNotices([...relevantNotices.filter(n => n.id !== notice.id)])
      hideNotice(notice)
    }

    //
    // RENDER
    //
    return (
      <Container style={{ padding: 0 }}>
        {(showAllHints ? relevantNotices : relevantNotices.slice(0, hintLimit)).map(notice => {
          const type = notice.contentType || NoticeContentTypeEnum.tip
          let textColor = 'text-color-turquoise-600'
          let iconName = 'bulb'

          if (type === NoticeContentTypeEnum.alert) {
            textColor = 'text-color-error'
            iconName = 'message-warning'
          }

          const CLASS_ROOT = 'notice'
          const classes = cx(
            CLASS_ROOT,
            {
              [`${CLASS_ROOT}--${type}`]: type,
            },
            `${textColor} mb-xlarge`
          )

          return (
            <div key={notice.id}>
              <div data-notice className={classes}>
                <Bar className="mb-none">
                  <BarItem>
                    <Icon name={iconName} size="xlarge" className="text-vam" />
                  </BarItem>

                  <BarItem isFilling>
                    {notice.renderContent ? notice.renderContent({ textColor }) : null}
                  </BarItem>

                  <BarItem>
                    <ButtonLinkSecondary equal size="tiny" onClick={() => onNoticeClose(notice)}>
                      <Icon name="close" size="medium" />
                    </ButtonLinkSecondary>
                  </BarItem>
                </Bar>
              </div>
            </div>
          )
        })}

        {!showAllHints && relevantNotices.length > hintLimit ? (
          <>
            <ButtonLayout direction={{ m: 'horizontal' }} className="align-items-right">
              <ButtonPrimary size="large" onClick={() => setShowAllHints(true)}>
                <Trans>Načítať všetky upozornenia</Trans>
              </ButtonPrimary>
            </ButtonLayout>
            <br />
          </>
        ) : null}
      </Container>
    )
  }
)
