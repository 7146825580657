import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'badge'

interface BadgeProps {
  className?: string
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
  color?: 'primary' | 'info' | 'turquoise'
  children?: React.ReactNode
}

type Props = React.HTMLAttributes<HTMLDivElement> & BadgeProps

const Badge: React.SFC<Props> = ({ className, size, color, children, ...other }) => {
  // FIXME: sizing and coloring from original UI kit does not work
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${size}`]: size,
      [`${CLASS_ROOT}--${color}`]: color,
    },
    className
  )

  return (
    <div className={classes} {...other}>
      {children}
    </div>
  )
}

export { Badge }
