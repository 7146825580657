import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'tab-panels__panel'

interface TabPanelOwnProps {
  /** Tab element renderer */
  tab: any // TODO: this can be typed better
  isActive?: boolean
}

type TabPanelProps = React.HTMLAttributes<HTMLDivElement> & TabPanelOwnProps

const TabPanel: React.SFC<TabPanelProps> = ({ children, className, isActive, tab, ...other }) => {
  const classes = cx(
    CLASS_ROOT,
    {
      'is-active': isActive,
    },
    className
  )

  const ariaLabeledBy = tab.props.id
  const id = tab.props.href.slice(1)

  return (
    <section
      id={id}
      className={classes}
      role="tabpanel"
      aria-labelledby={ariaLabeledBy}
      aria-hidden={!isActive}
      {...other}>
      {children}
    </section>
  )
}

export { TabPanel }
