import React from 'react'
import cx from 'classnames'
import { MobileNavItem } from './MobileNavItem'
import { Bar } from '../bar/Bar'

const CLASS_ROOT = 'mobile-nav'

type Item = {
  itemTitle: string
  href: string
  isActive?: boolean
}

interface MobileNavProps {
  /** Navigation items */
  items: Item[]
  /** Active mobile nav is visible to the user */
  isActive: boolean
  toggleButtonRef?: React.RefObject<HTMLSpanElement>
}

type Props = React.HTMLAttributes<HTMLDivElement> & MobileNavProps

const MobileNav: React.SFC<Props> = ({
  items = [],
  isActive,
  className,
  toggleButtonRef,
  ...other
}) => {
  const classes = cx(CLASS_ROOT, className, {
    'is-active': isActive,
  })

  const hideMenu = () => {
    if (toggleButtonRef && toggleButtonRef.current) toggleButtonRef.current.click()
  }

  return (
    <nav className={classes} {...other}>
      <Bar tag="ul" direction="vertical" className={`${CLASS_ROOT}__menu list--unstyled`}>
        {items.map(item => (
          <MobileNavItem key={item.itemTitle} {...item} onClick={hideMenu} />
        ))}
      </Bar>
      <div className={`${CLASS_ROOT}__overlay`} />
    </nav>
  )
}

export { MobileNav }
