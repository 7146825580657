import { CMS_CONFIG } from '../../../config'
import { cmsApi } from '../../api'

export class AuthCms {
  static async login() {
    const response = await cmsApi.api.post('/oauth/token', {
      grant_type: 'client_credentials',
      client_id: '2',
      client_secret: CMS_CONFIG[location.hostname] || CMS_CONFIG.localhost,
      scope: '*',
    })

    const { access_token } = response.data
    if (!access_token) {
      throw new Error('Token missing')
    }

    this.saveToken(access_token)
  }

  static saveToken(accessToken: string) {
    this.setAccessToken(accessToken)
  }

  static getAccessToken() {
    return localStorage.getItem('auth.accessTokenCMS')
  }

  static setAccessToken(token: string) {
    return localStorage.setItem('auth.accessTokenCMS', token)
  }
}
