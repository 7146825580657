import { observable } from 'mobx'
import { pullAllBy } from 'lodash-es'
import { CLASS_ROOT } from './Alert'

export class AlertEntity {
  key: string

  icon?: string

  iconSpritePath = ''

  closeable = true

  type?: 'success' | 'error'

  timeout = 5000

  classes = `${CLASS_ROOT}--active`

  content: React.ReactNode

  constructor(props: Partial<AlertEntity>) {
    this.key = Math.random().toString(5)
    Object.assign<AlertEntity, Partial<AlertEntity>>(this, props)
  }
}

const mapTypeToIcon = {
  error: 'message-error',
  success: 'message-success',
}

export const alertsStore = observable({
  alerts: [] as AlertEntity[],
  create(prop: Partial<AlertEntity>) {
    const alert = new AlertEntity(prop)

    if (!alert.icon && alert.type) {
      alert.icon = mapTypeToIcon[alert.type]
    }

    alertsStore.alerts.push(alert)

    if (alert.timeout) {
      setTimeout(() => {
        alertsStore.remove(alert)
      }, alert.timeout)
    }

    return alert
  },

  remove(...alerts: AlertEntity[]) {
    pullAllBy(alertsStore.alerts, alerts)
  },
})
