import { isFuture } from 'date-fns'
import { LogoutRequest } from '@odiupsk/up-api-client'
import { authApi } from '../../api'
import { clientSecret, CLIENT_ID } from '../../../config'
import { ApiAuthRepository } from '../../api/ApiAuthRepository'

export class Auth {
  static async login(username: string, password: string) {
    const response = await ApiAuthRepository.login(username, password)

    const { access_token } = response
    if (!access_token) {
      throw new Error('Token missing')
    }

    const { userId } = this.decodeToken(access_token)
    this.saveTokens(access_token, userId)

    return {
      userId,
    }
  }

  static async fetchNewAccessToken() {
    const response = await authApi.postToken({
      client_id: CLIENT_ID,
      client_secret: clientSecret,
      grant_type: 'refresh_token',
    })

    const { access_token } = response.data
    if (!access_token) {
      this.setAccessToken('')
      this.setUserId('')
      location.href = `/auth?redirect=${encodeURIComponent(location.pathname)}`
      throw new Error('Token missing')
    }

    this.saveTokens(access_token)
  }

  static async fetchNewAccessTokenWithoutRedirect() {
    const response = await authApi.postToken({
      client_id: CLIENT_ID,
      client_secret: clientSecret,
      grant_type: 'refresh_token',
    })

    const { access_token } = response.data
    if (!access_token) {
      this.setAccessToken('')
      this.setUserId('')
      location.href = `/auth`
      throw new Error('Token missing')
    }

    this.saveTokens(access_token)
  }

  static saveTokens(accessToken: string, userId?: string) {
    if (userId) this.setUserId(userId)
    this.setAccessToken(accessToken)
  }

  static decodeToken(
    token: string
  ): { userId: string; expiresIn: number; resetPassword: boolean; changeDummyEmail: boolean } {
    const {
      sub: userId,
      exp: expiresIn,
      reset_password: resetPassword,
      change_dummy_email: changeDummyEmail,
    } = JSON.parse(atob(this.base64UrlToBase64(token.split('.')[1])))
    return { userId, expiresIn, resetPassword, changeDummyEmail }
  }

  static base64UrlToBase64 = function(input: string) {
    // Replace non-url compatible chars with base64 standard chars
    let token = input.replace(/-/g, '+').replace(/_/g, '/')

    // Pad out with standard base64 required padding characters
    const pad = token.length % 4

    if (pad) {
      if (pad === 1) {
        throw new Error(
          'InvalidLengthError: Input base64url string is the wrong length to determine padding'
        )
      }
      token += new Array(5 - pad).join('=')
    }

    return token
  }

  static isTokenValid(token: string) {
    const { expiresIn } = this.decodeToken(token)
    return isFuture(new Date(expiresIn * 1000))
  }

  static forcePasswordReset(token: string) {
    const { resetPassword } = this.decodeToken(token)
    return resetPassword
  }

  static forceChangeEmail(token: string) {
    const { changeDummyEmail } = this.decodeToken(token)
    return changeDummyEmail
  }

  static getAccessToken() {
    return sessionStorage.getItem('auth.accessToken')
  }

  static setAccessToken(token: string) {
    return sessionStorage.setItem('auth.accessToken', token)
  }

  static getUserId() {
    return localStorage.getItem('auth.userId')
  }

  static setUserId(id: string) {
    return localStorage.setItem('auth.userId', id)
  }

  static async logout() {
    const body: LogoutRequest = {
      client_id: CLIENT_ID,
      client_secret: clientSecret,
    }
    await authApi.api.post(`/auth/logout`, body)
    this.setAccessToken('')
    this.setUserId('')
    location.href = '/auth'
  }
}
