import React from 'react'

export const canUseDOM = typeof window !== 'undefined'

/**
 * Render component only on client
 * ```js
 clientOnly(() => <Foo />)

 @clientOnly
 class Foo extends React.Component{}
 ```
 */
export const clientOnly = <T extends {}>(WrappedComponent: React.ComponentType<T>) => {
  return (props: T) => {
    if (!canUseDOM) {
      return null
    }
    return <WrappedComponent {...props} />
  }
}
