import React, { useEffect, useRef, ReactNode, Fragment, cloneElement, isValidElement } from 'react'
import cx from 'classnames'
import { useTooltip, TooltipPopup } from '@reach/tooltip'
import Portal from '@reach/portal'
import { createPortal } from 'react-dom'
import { withUniqueId } from 'react-maria'
import { TooltipStatic } from './Tooltip.static'

const CLASS_ROOT = 'tooltip'

interface TooltipProps {
  /** Tooltip trigger content */
  content?: ReactNode

  /** Tooltip dialog content */
  dialog?: ReactNode

  /** Class names applied to dialog */
  dialogClassName?: string

  /** Tooltip ID */
  id: string
}

const TooltipDialog: React.SFC<{
  id: string
  dialogClasses: string
  dialogRef: React.MutableRefObject<HTMLSpanElement | null>
  dialog: ReactNode
}> = ({ id, dialogClasses, dialogRef, dialog }) => (
  <span
    key="tooltipDialog"
    id={id}
    className={dialogClasses}
    role="tooltip"
    aria-hidden="true"
    ref={dialogRef}
    data-tooltip>
    <span className="tooltip__inner">
      <span className="tooltip__arrow" />
      <span className="tooltip__content">{dialog}</span>
    </span>
  </span>
)

type Props = React.HTMLAttributes<HTMLSpanElement> & TooltipProps

const TooltipInner: React.SFC<Props> = props => {
  const triggerRef = useRef<HTMLDivElement | null>(null)
  const dialogRef = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    const tooltip = new TooltipStatic(dialogRef.current!, triggerRef.current!, {
      isStatic: false,
    })

    return () => {
      tooltip.destroy()
    }
  }, [])

  const { content, dialog, className, dialogClassName, id, ...other } = props
  const dialogClasses = cx(CLASS_ROOT, dialogClassName)

  delete (other as any).getMariaIdTools

  return (
    <>
      <div
        style={{ display: 'flex' }}
        key="tooltipTrigger"
        tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        className={className}
        aria-describedby={id}
        ref={triggerRef}
        {...other}>
        {content}
      </div>
      {typeof document === 'undefined' ? (
        <TooltipDialog
          id={id}
          dialogClasses={dialogClasses}
          dialogRef={dialogRef}
          dialog={dialog}
        />
      ) : (
        createPortal(
          <TooltipDialog
            id={id}
            dialogClasses={dialogClasses}
            dialogRef={dialogRef}
            dialog={dialog}
          />,
          document.querySelector('#root-tooltips')!
        )
      )}
    </>
  )
}

export const Tooltip = withUniqueId()(TooltipInner)

// Center the tooltip, but collisions will win
const centered = (triggerRect: DOMRect, tooltipRect: DOMRect): DOMRect => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2
  const left = triggerCenter - tooltipRect.width / 2
  const maxLeft = window.innerWidth - tooltipRect.width - 2
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - tooltipRect.height - 7 + window.scrollY,
  } as any
}

export const ReachTooltip: React.FC<{
  label: string | ReactNode
  ariaLabel: string
}> = ({ children, label, ariaLabel }) => {
  const [trigger, tooltip] = useTooltip()

  // destructure off what we need to position the triangle
  const { isVisible, triggerRect } = tooltip

  return (
    <Fragment>
      {isValidElement(children) && cloneElement(children, trigger)}

      {isVisible && triggerRect && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions
        <Portal>
          <div
            style={{
              position: 'absolute',

              left: triggerRect.left + triggerRect.width / 2,
              top: triggerRect.top - 7 + window.scrollY,
              border: 'solid transparent',

              height: 0,
              width: 0,

              pointerEvents: 'none',
              borderColor: 'rgba(0, 0, 0, 0)',
              borderTopColor: 'rgba(72,84,77,.9)',
              borderWidth: 6,
              marginLeft: -6,
              zIndex: 1600,
            }}
          />
        </Portal>
      )}
      <TooltipPopup
        data-reach-tooltip
        {...tooltip}
        label={label}
        ariaLabel={ariaLabel}
        style={{
          background: 'rgba(72,84,77,.9)',
          color: 'white',
          border: 'none',
          borderRadius: '3px',
          padding: '0.5em 1em',
        }}
        position={centered}
      />
    </Fragment>
  )
}
