import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'form-label'

type Props = React.HTMLAttributes<HTMLLabelElement> & {
  isError?: boolean
  id?: string
  size?: 'small' | 'large'
}

const Label: React.SFC<Props> = ({
  className,
  children,
  id,
  isError,
  size = 'small',
  ...other
}: Props) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${size}`]: size,
      'is-error': isError,
    },
    className
  )

  return (
    <div className={classes}>
      <label htmlFor={id} {...other}>
        {children}
      </label>
    </div>
  )
}

export { Label }
