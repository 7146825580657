import bugsnag from '@bugsnag/js'
import { format } from 'util'
import getConfig from 'next/config'
/* eslint-disable no-param-reassign */
import { getGlobalAxios } from '@odiupsk/up-api-client'
import { axiosParamsToSequence } from '../common/utils/axiosParamsToSequence'
import { AuthCms } from '../common/modules/auth/AuthCms'
import { Auth } from '../common/modules/auth/Auth'

const { publicRuntimeConfig } = getConfig()
const {
  CMS_API_BASE_URL,
  UP_API_BASE_URL,
  API_TOKEN,
  API_KEY,
  API_KEY_PRODUCTION,
  CLIENT_SECRET,
  CLIENT_SECRET_PRODUCTION,
  CLIENT_ID,
  RECAPTCHA_SITE_KEY,
  GOOGLE_ANALYTICS_KEY,
  BUGSNAG_KEY,
  LAST_BUILD_DATE,
} = publicRuntimeConfig

export {
  CMS_API_BASE_URL,
  UP_API_BASE_URL,
  API_TOKEN,
  CLIENT_ID,
  RECAPTCHA_SITE_KEY,
  GOOGLE_ANALYTICS_KEY,
  BUGSNAG_KEY,
  LAST_BUILD_DATE,
}

const DEV_HOSTNAMES = [
  'moje-preprod.up-slovensko.sk',
  'moje-dev.up-slovensko.sk',
  'moje-test.up-slovensko.sk',
  'moje-uat.up-slovensko.sk',
  'stravovanie-preprod.mojeup.sk',
  'stravovanie-uat.mojeup.sk',
  'localhost',
]

export const IS_DEMO = process.browser && DEV_HOSTNAMES.includes(location?.hostname)

const API_KEY_CONFIG: Record<string, string> = {
  'moje.up-slovensko.sk': API_KEY_PRODUCTION,
  'moje-preprod.up-slovensko.sk': API_KEY_PRODUCTION,
  'moje-dev.up-slovensko.sk': API_KEY,
  'moje-test.up-slovensko.sk': API_KEY,
  'moje-uat.up-slovensko.sk': API_KEY,
  'stravovanie.mojeup.sk': API_KEY_PRODUCTION,
  'stravovanie-preprod.mojeup.sk': API_KEY_PRODUCTION,
  'stravovanie-uat.mojeup.sk': API_KEY,
  localhost: API_KEY,
}

const CLIENT_SECRET_CONFIG: Record<string, string> = {
  'moje.up-slovensko.sk': CLIENT_SECRET_PRODUCTION,
  'moje-preprod.up-slovensko.sk': CLIENT_SECRET_PRODUCTION,
  'moje-dev.up-slovensko.sk': CLIENT_SECRET,
  'moje-test.up-slovensko.sk': CLIENT_SECRET,
  'moje-uat.up-slovensko.sk': CLIENT_SECRET,
  'stravovanie.mojeup.sk': CLIENT_SECRET_PRODUCTION,
  'stravovanie-preprod.mojeup.sk': CLIENT_SECRET_PRODUCTION,
  'stravovanie-uat.mojeup.sk': CLIENT_SECRET,
  localhost: CLIENT_SECRET,
}

const STATISTICS_URL_CONFIG: Record<string, string> = {
  'moje.up-slovensko.sk': 'https://gamification.up-dejeuner.sk',
  'moje-preprod.up-slovensko.sk': 'https://gamification.up-dejeuner.sk',
  'moje-dev.up-slovensko.sk': 'https://gamification-test.up-dejeuner.sk',
  'moje-test.up-slovensko.sk': 'https://gamification-test.up-dejeuner.sk',
  'moje-uat.up-slovensko.sk': 'https://gamification-test.up-dejeuner.sk',
  'stravovanie.mojeup.sk': 'https://gamification.up-dejeuner.sk',
  'stravovanie-preprod.mojeup.sk': 'https://gamification.up-dejeuner.sk',
  'stravovanie-uat.mojeup.sk': 'https://gamification-test.up-dejeuner.sk',
  localhost: 'https://gamification-test.up-dejeuner.sk',
}

export const CMS_CONFIG: Record<string, string> = {
  'moje.up-slovensko.sk': 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
  'moje-preprod.up-slovensko.sk': 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
  'moje-dev.up-slovensko.sk': 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
  'moje-test.up-slovensko.sk': 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
  'moje-uat.up-slovensko.sk': 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
  'stravovanie.mojeup.sk': 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
  'stravovanie-preprod.mojeup.sk': 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
  'stravovanie-uat.mojeup.sk': 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
  localhost: 'BO95Keo0oG9aHk41qCB1unCSmT1VnUqZTKMwE0mc',
}

export const statisticsDomain = process.browser
  ? STATISTICS_URL_CONFIG[location.hostname] || STATISTICS_URL_CONFIG.localhost
  : STATISTICS_URL_CONFIG.localhost

export const statisticsOverviewPath = 'statistika'
export const statisticsGamesPath = 'sutaze'

const apiKey = process.browser
  ? API_KEY_CONFIG[location.hostname] || API_KEY_CONFIG.localhost
  : API_KEY_CONFIG.localhost

const clientSecret = process.browser
  ? CLIENT_SECRET_CONFIG[location.hostname] || CLIENT_SECRET_CONFIG.localhost
  : CLIENT_SECRET_CONFIG.localhost

export { clientSecret, apiKey }

// eslint-disable-next-line import/no-mutable-exports
let bugsnagClient: any

if (process.env.NODE_ENV !== 'test') {
  bugsnagClient = bugsnag({
    apiKey: BUGSNAG_KEY,
    autoNotify: process.env.NODE_ENV !== 'development',
    ...(process.env.NODE_ENV === 'development' && { notifyReleaseStages: [] }),
  })
} else {
  bugsnagClient = {
    notify: () => ({}),
  }
}

export { bugsnagClient }

export const cmsAxios = getGlobalAxios().create({
  baseURL: CMS_API_BASE_URL,
})

cmsAxios.interceptors.request.use(req => {
  const accessToken = AuthCms.getAccessToken()
  if (accessToken) req.headers.Authorization = `Bearer ${accessToken}`
  return req
})

export const upAxios = getGlobalAxios().create({
  baseURL: UP_API_BASE_URL,
  headers: {
    'X-API-KEY': apiKey,
    'X-Language': `1`,
    'X-Frame-Options': 'SAMEORIGIN',
  },
  paramsSerializer: (data: any) => {
    const params = new URLSearchParams(axiosParamsToSequence(data)).toString()
    return params
  },
})

const omitAuthRedirectUrls: string[] = [
  '/client/order',
  '/client/order/result',
  '/product-selection',
]

const onlyToAuthRedirectUrls: string[] = ['/auth/change-email']

export const shouldOmitRedirect = () =>
  omitAuthRedirectUrls.includes(location.pathname.replace(/\/$/, ''))

export const shouldRedirectOnlyToAuth = () =>
  onlyToAuthRedirectUrls.includes(location.pathname.replace(/\/$/, ''))

const showLivechatUrls: string[] = [
  '/product-selection',
  '/client/product-selection',
  '/client/order',
  '/client/order/result',
  '/auth',
]

export const shouldShowLivechat = () =>
  showLivechatUrls.includes(location.pathname.replace(/\/$/, ''))

if (process.env.NODE_ENV === 'test') {
  ;['request', 'get', 'post', 'patch', 'put', 'delete'].forEach(i => {
    const mock = jest.fn((...args) => {
      throw new Error(`Outgoing requests in TEST are forbidden ${format(args)}`)
    })
    ;(upAxios as any)[i] = mock
    ;(cmsAxios as any)[i] = mock
  })
}

upAxios.interceptors.request.use(req => {
  const accessToken = Auth.getAccessToken()
  if (accessToken) req.headers.Authorization = `Bearer ${accessToken}`

  if (JSON.stringify(req.params || '').length > 2000 && req.method === 'get') {
    req.method = 'post'
    req.data = req.params
    req.params = undefined
  }

  return req
})

upAxios.interceptors.response.use(
  response => {
    return response
  },
  async err => {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'test') console.log(err)

    if (
      err.response.status === 401 &&
      err.config &&
      !upAxios.defaults.headers.common.isRetryRequest
    ) {
      try {
        upAxios.defaults.headers.common.isRetryRequest = 1
        await Auth.fetchNewAccessToken()

        const accessToken = Auth.getAccessToken()
        err.config.headers.Authorization = `Bearer ${accessToken}`
        // reset baseURL otherwise it would be prepended to url again
        err.config.baseURL = ''

        upAxios.defaults.headers.common.isRetryRequest = 0
        return upAxios(err.config)
      } catch (error) {
        upAxios.defaults.headers.common.isRetryRequest = 0

        if (shouldOmitRedirect()) return

        if (shouldRedirectOnlyToAuth()) {
          location.href = `/auth`
          return
        }

        location.href = `/auth?redirect=${encodeURIComponent(location.pathname)}`
      }
    }

    throw err
  }
)

cmsAxios.interceptors.response.use(
  response => {
    return response
  },
  async err => {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'test') console.log(err)

    if (
      err.response.status === 401 &&
      err.config &&
      !cmsAxios.defaults.headers.common.isRetryRequest
    ) {
      try {
        cmsAxios.defaults.headers.common.isRetryRequest = 1
        await AuthCms.login()

        const accessToken = AuthCms.getAccessToken()
        err.config.headers.Authorization = `Bearer ${accessToken}`
        // reset baseURL otherwise it would be prepended to url again
        err.config.baseURL = ''
        cmsAxios.defaults.headers.common.isRetryRequest = 0

        return cmsAxios(err.config)
      } catch (error) {
        cmsAxios.defaults.headers.common.isRetryRequest = 0
      }
    }

    throw err
  }
)
