import React from 'react'
import cx from 'classnames'

interface Props {
  className?: string

  /** Html element. */
  tag?: keyof React.ReactHTML

  /** Gutter size */
  gutters?: 'condensed'
}

const CLASS_ROOT = 'grid'

const Grid: React.SFC<Props> = ({ className, tag = 'div', gutters, ...other }) => {
  const classes = cx(CLASS_ROOT, className, {
    [`${CLASS_ROOT}--${gutters}`]: gutters,
  })
  const TagName = tag

  return React.createElement(TagName, { className: classes, ...other })
}

export { Grid }
