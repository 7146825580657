import React from 'react'
import cx from 'classnames'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { Grid, GridCol } from '../grid'
import { Bar } from '../bar/Bar'
import { BarItem } from '../bar/BarItem'
import { ButtonDropdown } from '../button/ButtonDropdown'
import { DropdownItem } from '../dropdown/DropdownItem'
import { Icon } from '../icon'
import { List } from '../list/List'
import { MainNavAction } from './MainNavAction'
import { MainNavItem } from './MainNavItem'
import { appStore } from '../../AppStore'
import { getFaqsPageHref } from '../../modules/contact/GetFaqsPageHref'

const CLASS_ROOT = 'main-nav'

export type Item = {
  itemTitle: string
  href: string
  isActive?: boolean
  className?: string
  logoTop?: React.ReactElement
}

type DropdownStartPos = {
  xs?: number
  s?: number
  m?: number
  l?: number
  xl?: number
}

interface MainNavProps {
  /** Logo element. Can be wrapped in an anchor tag. */
  logo?: React.ReactNode
  /** Navigation items */
  items?: Item[]
  /** Navigation action items displayed on the right side */
  actions?: React.ReactNode[]
  actionsFirst?: boolean
  /** Responsive map specifying how many items are visible per breakpoint. The rest is moved to a dropdown. */
  dropdownStartPos?: DropdownStartPos
  /** Class name for menu item */
  itemClassName?: string
  hideHelp?: boolean
}

type Props = React.HTMLAttributes<HTMLDivElement> & MainNavProps

export const MainNav = ({
  logo,
  items = [],
  dropdownStartPos = {},
  actions = [],
  actionsFirst,
  className,
  itemClassName,
  hideHelp,
  ...other
}: Props) => {
  const router = useRouter()

  const classes = cx(CLASS_ROOT, className)
  let itemsNav = items.slice()
  let itemsDropdown = items.slice()

  // for xl shoud be hide-xl
  // for xs should be hide
  // rest can be hide-[s,m,l]-only
  const breakpointClasses = {
    xs: 'hide',
    s: 'hide-s-only',
    m: 'hide-m-only',
    l: 'hide-l-only',
    xl: 'hide-xl',
  }
  const breakpoints = Object.keys(breakpointClasses) as Array<keyof typeof breakpointClasses>

  const dropdownFromArray = Object.keys(dropdownStartPos) as Array<keyof DropdownStartPos>
  const showDropdown = dropdownFromArray.length > 0 && itemsNav.length > 4

  if (showDropdown) {
    breakpoints.reverse().forEach(breakpoint => {
      const hideDownfrom = dropdownStartPos[breakpoint]
        ? dropdownStartPos[breakpoint]! - 1
        : items.length + 1

      itemsNav = itemsNav.map((item, id) => {
        if (id < hideDownfrom) return item
        return {
          ...item,
          className: cx(
            {
              [`${item.className}`]: item.className,
            },
            `${breakpointClasses[breakpoint]}`
          ),
        }
      })
    })

    breakpoints.forEach(breakpoint => {
      const hideDownfrom = dropdownStartPos[breakpoint] ? dropdownStartPos[breakpoint]! - 1 : 0

      itemsDropdown = itemsDropdown.map((item, id) => {
        if (id >= hideDownfrom) return item
        return {
          ...item,
          className: cx(
            {
              [`${item.className}`]: item.className,
            },
            `${breakpointClasses[breakpoint]}`
          ),
        }
      })
    })
  }

  if (
    router.pathname.includes('/profile') ||
    // lognuty userko s 0 scopmi
    (appStore.currentUser && appStore.currentUserAccesses.length === 0)
  ) {
    itemsNav = []
  }

  /*
   * For routes included in regularLinkHrefMatches,
   * render a regular <a href=""></a> instead of Next.js Link.
   * This is to reload entire page and fetch client Data with permissions
   */
  const renderRegularLink = (href: string) => {
    const regularLinkHrefMatches = ['/client/documents-archive']
    return regularLinkHrefMatches.includes(href)
  }

  return (
    <nav className={classes} {...other}>
      <Bar tag="ul" className="list--unstyled mb-none align-items-stretch">
        {logo && (
          <BarItem tag="li" className={`${CLASS_ROOT}__logo mr-xxlarge`}>
            <Link href="/">{logo}</Link>
          </BarItem>
        )}

        {actions &&
          actionsFirst &&
          actions.map(action => (
            // TODO: add string keys
            <MainNavAction>{action}</MainNavAction>
          ))}

        {itemsNav.map(({ className: itemNavClassName, itemTitle, ...rest }) => (
          <MainNavItem
            key={itemTitle}
            className={cx(itemClassName, {
              [`${itemNavClassName}`]: itemNavClassName,
            })}
            {...rest}>
            {itemTitle}
          </MainNavItem>
        ))}
        {showDropdown && (
          <MainNavItem className="show-l">
            <ButtonDropdown
              icon
              iconName="dots"
              iconSize="medium"
              iconRight={false}
              iconToggle="dots"
              type="link-secondary"
              space="condensed"
              className="align-self-middle"
              dropdownArrow
              popperOptions={{
                modifiers: {
                  arrow: {
                    element: '.dropdown__arrow',
                  },
                },
              }}>
              <List space="condensed" className="list--unstyled mb-none">
                {itemsDropdown.map(
                  ({
                    itemTitle,
                    href,
                    isActive,
                    logoTop,
                    className: itemDropdownClassName,
                    ...rest
                  }) => (
                    <>
                      {renderRegularLink(href) ? (
                        <a key={itemTitle} href={href}>
                          <DropdownItem
                            className={cx('show-l', {
                              [`${itemDropdownClassName}`]: itemDropdownClassName,
                              'is-active': isActive ?? (router ? router.pathname === href : false),
                            })}
                            {...rest}>
                            {logoTop}
                            {itemTitle}
                          </DropdownItem>
                        </a>
                      ) : (
                        <Link key={itemTitle} href={href}>
                          <a>
                            <DropdownItem
                              className={cx('show-l', {
                                [`${itemDropdownClassName}`]: itemDropdownClassName,
                                'is-active':
                                  isActive ?? (router ? router.pathname === href : false),
                              })}
                              {...rest}>
                              <Grid>
                                <GridCol>
                                  <div className="w-1 flex align-items-right">{logoTop}</div>
                                  {itemTitle}
                                </GridCol>
                              </Grid>
                            </DropdownItem>
                          </a>
                        </Link>
                      )}
                    </>
                  )
                )}
              </List>
            </ButtonDropdown>
          </MainNavItem>
        )}

        {actions && !actionsFirst && <BarItem tag="li" isFilling />}
        {!hideHelp && (
          <BarItem tag="li" className="main-nav__action">
            <Link href={getFaqsPageHref()}>
              <a className="btn btn--equal btn--condensed btn--link-secondary">
                <Icon size="medium" name="help-ico" />
              </a>
            </Link>
          </BarItem>
        )}
        {actions &&
          !actionsFirst &&
          actions.map(action => (
            // TODO: add string keys
            <MainNavAction>{action}</MainNavAction>
          ))}
      </Bar>
    </nav>
  )
}
