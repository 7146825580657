import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'card'

interface CardProps {
  className?: string
  /** Href for clickable card. You must include an accessible anchor (i.e. on a heading) for the [block-link utility](/styleguide/utilities/block-link) */
  href?: string
  /** Background color */
  color?: string
  children?: React.ReactNode
}

type Props = React.HTMLAttributes<HTMLDivElement> & CardProps

const Card: React.SFC<Props> = ({ className, href, color, children, ...other }) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--clickable`]: href,
      [`${CLASS_ROOT}--${color}`]: color,
      'block-link': href,
    },
    className
  )

  return (
    <div className={classes} {...other}>
      {children}
      {href && <a href={href} tabIndex={-1} className="block-link__overlay" /> // eslint-disable-line jsx-a11y/anchor-has-content
      }
    </div>
  )
}

export { Card }
