import React from 'react'
import cx from 'classnames'

// TODO: add support for vertical centering

export interface BarItemProps {
  /** Bar item should fill empty space */
  isFilling?: boolean

  /** Bar item could shrink and wrap content if there is not enough empty space */
  canShrink?: boolean

  /** Html tag in which the bar rendered. */
  tag?: keyof React.ReactHTML

  className?: string
  children?: React.ReactNode
}

const CLASS_ROOT = 'bar__item'

const BarItem = <T extends React.HTMLAttributes<HTMLElement>>({
  className,
  isFilling,
  canShrink,
  tag = 'div',
  ...other
}: T & BarItemProps) => {
  const Tag = tag
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--fill`]: isFilling,
      [`${CLASS_ROOT}--shrink`]: canShrink,
    },
    className
  )

  return React.createElement(Tag, { className: classes, ...other })
}

export { BarItem }
