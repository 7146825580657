/* eslint-disable class-methods-use-this */
import {
  ForgotPasswordBody,
  ForgotPasswordResult,
  AccessTokenResponse,
} from '@odiupsk/up-api-client'
import { AxiosResponse } from 'axios'
import { i18n } from '../utils/intl'
import { logError } from './utils'
import { authApi } from '.'
import { CLIENT_ID, clientSecret } from '../../config'

export class ApiAuthRepository {
  static async postForgotPassword(body: ForgotPasswordBody) {
    try {
      const res = await authApi.postForgotPassword(body)
      return res.data as ForgotPasswordResult
    } catch (err) {
      // logError(err, i18n.t`Odoslanie emailu zlyhalo`)
      throw err
    }
  }

  static async resetPasswordEmail(password: string, token: string) {
    try {
      return await authApi.postResetPassword({
        new_password: password,
        token,
      })
    } catch (err) {
      logError(err, i18n.t`Zmena hesla skončila chybou`)
      throw err
    }
  }

  static async resetPasswordPhone(password: string, code: string) {
    try {
      return await authApi.api.post(`/auth/reset_password_phone`, {
        new_password: password,
        code,
      })
    } catch (err) {
      logError(err, i18n.t`Zmena hesla skončila chybou`)
      throw err
    }
  }

  static async resetPassword(
    channel: 'email' | 'phone',
    args: { password: string; code?: string; token?: string }
  ) {
    if (channel === 'email' && args.token)
      await ApiAuthRepository.resetPasswordEmail(args.password, args.token)
    else if (channel === 'phone' && args.code)
      await ApiAuthRepository.resetPasswordPhone(args.password, args.code)
    else throw new Error('Unknow option')
  }

  static async login(username: string, password: string) {
    try {
      const response: AxiosResponse<AccessTokenResponse> = await authApi.postToken({
        client_id: CLIENT_ID,
        client_secret: clientSecret,
        grant_type: 'password',
        username,
        password,
      })
      return response.data
    } catch (err) {
      throw err
    }
  }
}
