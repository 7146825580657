import React from 'react'
import cx from 'classnames'

const CLASS_TABLIST_ITEM = 'tab-list__item'
const CLASS_TABLIST_TAB = 'tab-list__tab'
const CLASS_TAB = 'tab'

interface TabOwnProps {
  id: string
  href: string
  isDisabled?: boolean
  isActive?: boolean
  type?: 'link' | 'link-white'
  size?: 'medium' | 'large'
}

export type TabProps = React.HTMLAttributes<HTMLAnchorElement> & TabOwnProps

const Tab: React.SFC<TabProps> = ({
  children,
  className,
  id,
  href,
  isDisabled,
  isActive,
  type,
  size,
  ...other
}) => {
  const tabItemClasses = cx(CLASS_TABLIST_ITEM, {
    'is-active': isActive,
  })

  const tabClasses = cx(
    CLASS_TABLIST_TAB,
    CLASS_TAB,
    {
      'is-disabled': isDisabled,
      [`${CLASS_TAB}--${type}`]: type,
      [`${CLASS_TAB}--${size}`]: size,
    },
    className
  )

  return (
    <li className={tabItemClasses}>
      <a
        id={id}
        className={tabClasses}
        href={href}
        tabIndex={isDisabled ? -1 : 0}
        role="tab"
        aria-selected={isActive}
        aria-controls={href.slice(1)}
        {...other}>
        {children}
      </a>
    </li>
  )
}

export { Tab }
