import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import { clientOnly } from '../../utils/hoc'
import { AlertEntity, alertsStore } from './Store'

export const CLASS_ROOT = 'alert'

const Alert: React.SFC<{
  config: AlertEntity
  onClose: (event: React.MouseEvent) => void
}> = ({ config, onClose, children }) => {
  const classes = cx(config.type && `${CLASS_ROOT}--${config.type}`, config.classes)
  return (
    <div className={`alert alert--fixed ${classes}`}>
      <div className="bar mb-none bar--horizontal align-items-middle">
        {config.icon && (
          <div className="bar__item">
            <svg className="icon icon--medium text-vam" role="presentation" aria-hidden="true">
              <use xlinkHref={`${config.iconSpritePath}#${config.icon}`} />
            </svg>
          </div>
        )}
        <div className="bar__item bar__item--fill">{children}</div>
        {config.closeable && (
          <div className="bar__item">
            <button
              type="button"
              onClick={onClose}
              className="btn btn--link-secondary btn--tiny btn--equal">
              <svg className="icon icon--medium icon--close" role="presentation" aria-hidden="true">
                <use xlinkHref={`${config.iconSpritePath}#close`} />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

interface AlertsProps {
  /** Container to render alert */
  container?: string
}

const Alerts: React.SFC<AlertsProps> = clientOnly(
  observer(({ container = '#root-alerts' }) => {
    const containerRef = useRef(document.querySelector(container))

    const alerts = alertsStore.alerts.map(i => (
      <Alert key={i.key} config={i} onClose={_ => alertsStore.remove(i)}>
        {i.content}
      </Alert>
    ))

    return containerRef.current ? createPortal(<>{alerts}</>, containerRef.current) : null
  })
)

export { Alerts, Alert }
