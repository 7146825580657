import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'list__item'

interface ListItemProps {
  className?: string
  children?: React.ReactNode
  /** Clickable list item */
  clickable?: boolean
  /** Selected state */
  isSelected?: boolean
  /** Disabled state */
  isDisabled?: boolean
}

type Props = React.HTMLAttributes<HTMLLIElement> & ListItemProps

const ListItem: React.SFC<Props> = ({
  className,
  children,
  clickable,
  isSelected,
  isDisabled,
  ...other
}) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--clickable`]: clickable,
      'is-selected': isSelected,
      'is-disabled': isDisabled,
    },
    className
  )

  return (
    <li className={classes} {...other}>
      {children}
    </li>
  )
}

export { ListItem }
