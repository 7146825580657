import React from 'react'
import cx from 'classnames'
import { Label } from '../label/Label'
import { Message, MessageOwnProps } from '../message/Message'

const CLASS_ROOT = 'radiocheck'

interface RadioOwnProps {
  /** Array of messages for radio or checkbox  shape [{ type: 'help', text: 'Text help' }, {type: 'error', text: 'Text error'}] */
  messages?: {
    type?: MessageOwnProps['type']
    text: React.ReactNode
    icon?: string
    iconSpritePath?: string
  }[]

  /** Html id attribute. */
  id?: string

  /** Active state. */
  isActive?: boolean

  /** Disabled state. */
  isDisabled?: boolean

  /** Readonly state. */
  isReadonly?: boolean

  /** Label text. */
  label?: string | object

  /** Has error */
  error?: boolean

  /** Checked state. */
  isChecked?: boolean

  /** Label className. */
  labelClassName?: string

  /** form-control className. */
  formControlClass?: string

  /** Custom label renderer. Passes props as function parameter. */
  renderLabel?: Function

  /** Html name attribute. */
  name?: string

  /** Size of element. */
  size?: 'small'

  /** Input html type */
  type: 'radio' | 'checkbox'

  /** Wrap children in label element  */
  wrapChildrenInLabel?: boolean

  /** Only label is visible */
  onlyLabel?: boolean
}

type Props = React.HTMLAttributes<HTMLInputElement> & RadioOwnProps

const RadioCheck: React.FC<Props> = props => {
  const {
    className,
    children,
    isActive,
    isChecked,
    isDisabled,
    isReadonly,
    messages = [],
    id,
    label,
    labelClassName,
    formControlClass,
    renderLabel,
    onChange,
    size,
    type,
    wrapChildrenInLabel = true,
    onlyLabel,
    error,
    ...other
  } = props

  // eslint-disable-next-line no-console
  if (label) {
    // eslint-disable-next-line no-console
    console.assert(id, 'If label is set, also id has to be')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(e)
  }

  const classes = cx(
    CLASS_ROOT,
    type,
    {
      [`${CLASS_ROOT}--${size}`]: size,
      [`${CLASS_ROOT}--only-label`]: onlyLabel,
    },
    className
  )

  const labelClasses = cx(`${CLASS_ROOT}__label`, labelClassName)

  const isError = error || messages.some(m => m.type === 'error')

  const inputClasses = cx(`${CLASS_ROOT}__input`, {
    'is-active': isActive,
    'is-error': isError,
  })

  const formControlClasses = cx('form-control', `form-control--${type}`, formControlClass)

  // set name from id if name is not specfied
  let { name } = other
  name = name && id

  const inputLabel =
    (renderLabel && renderLabel(props)) ||
    (label && (
      <Label id={id} size={size} isError={isError}>
        {label}
      </Label>
    ))

  const messagesOutput = messages.map((m, idx) => (
    <Message key={idx} type={m.type} icon={m.icon}>
      {m.text}
    </Message>
  ))

  const childrenOutput =
    (wrapChildrenInLabel && (
      <label className={labelClasses} htmlFor={id}>
        {children}
      </label>
    )) ||
    children

  return (
    <div className={formControlClasses}>
      {inputLabel}
      <div className={classes}>
        <input
          className={inputClasses}
          type={type}
          id={id}
          name={name}
          checked={isChecked}
          disabled={isDisabled}
          readOnly={isReadonly}
          onChange={handleChange}
          {...other}
        />
        <div className={`${CLASS_ROOT}__control`} />
        <div className={`${CLASS_ROOT}__content`}>
          {childrenOutput}
          {messagesOutput}
        </div>
      </div>
    </div>
  )
}

const Radio: React.FC<Omit<Props, 'type'>> = props => <RadioCheck type="radio" {...props} />

const Checkbox: React.FC<Omit<Props, 'type'>> = props => <RadioCheck type="checkbox" {...props} />

export { Checkbox, Radio }
