import { getCurrentScopeUrl } from '../common/utils/routing'

export enum SCOPES {
  Client = 1,
  Affiliate = 2,
  Holder = 3,
  Branch = 4,
  DeliveryAddress = 5,
  Merchant = 6,
  Public = 100,
  User = 101, // ODI user only, without authorization
}

export const SupportedBroswerMap: Record<string, string> = {
  Chrome: '72',
  Firefox: '65',
  'Internet Explorer': '12',
  Safari: '12',
  'Mobile Safari': '12',
  Edge: '18',
  'iOS Safari': '12',
  'Android Browser': '67',
}

export enum CLIENT_EMPLOYEES_BULK_OPERATIONS {
  Delete = 1,
  LinkDeliveryAddress = 2,
  LinkDepartment = 3,
  Activate = 4,
  Deactivate = 5,
}

export enum CLIENT_CARDS_BULK_OPERATIONS {
  Activate = 1,
  Block = 2,
  Deactivate = 3,
}

export enum ORDER_USER_STATUS {
  Client = 'CLIENT',
  Anon = 'ANON',
}

export enum PRODUCT_TYPE {
  Voucher = 1,
  Card = 2,
}

export enum VOUCHER_PACKAGING {
  Prepacked = 1,
  Individual = 2,
  Configurable = 3,
}

export enum PERSONALIZATION_TYPE {
  Name = 1,
  Number = 2,
  NameAndNumber = 3,
}

export enum ORDERING_TYPE {
  Custom = 1,
  Surname = 2,
  Number = 3,
}

export enum PRODUCT {
  SHELL = 'SHELL',
  DEJEUNER = 'DEJEUNER',
}

export type ProductType = {
  code: PRODUCT
  id: number
  name: string
  description: string
  descriptionAdminRights: string
  iconCode: string
  backgroundColor: string
  pages: {
    id: string
    href: string
  }[]
}

export const PRODUCTS: { [productKey: string]: ProductType } = {
  SHELL: {
    code: PRODUCT.SHELL,
    id: 11,
    name: 'Shell',
    description: 'Palivová karta',
    descriptionAdminRights: 'Oprávnenia palivová karta Shell',
    iconCode: 'shell',
    backgroundColor: '#E46A49',
    pages: [
      {
        id: `transactions`,
        href: '/client/shell/transactions',
      },
      {
        id: `cards`,
        href: '/client/shell/cards',
      },
      {
        id: `acceptation-places`,
        href: '/client/shell/acceptation-places',
      },
    ],
  },

  DEJEUNER: {
    code: PRODUCT.DEJEUNER,
    id: 9,
    name: 'UpDéjeuner',
    description: 'Karta',
    descriptionAdminRights: 'Oprávnenia stravovacia karta UpDéjeuner',
    iconCode: 'card-with-hand',
    backgroundColor: '#EB8C2D',
    pages: [
      {
        id: `product-selection`,
        href: '/client/product-selection',
      },
      {
        id: `cards`,
        href: '/client/dejeuner/cards',
      },
      {
        id: `orders-history`,
        href: '/client/orders-history',
      },
      {
        id: `acceptation-places`,
        href: '/client/dejeuner/acceptation-places',
      },
      {
        id: 'order',
        href: '/client/order',
      },
    ],
  },
}

export const getProductPageHref = (product: PRODUCT, pageId: string) => {
  return PRODUCTS[product]?.pages?.find(p => p.id === pageId)?.href || '/'
}

export const DEFAULT_VOUCHER_VALUE = 380
export const DEFAULT_MIN_VOUCHER_VALUE = 100
export const DEFAULT_MAX_VOUCHER_VALUE = 2000

export const MAX_NUMBER_OF_EMPLOYEES_PICKED_FROM_TABLE_DURING_ORDER = 100

export const ACTIVATE_ORDERED_CREDIT_MINIMUM_DAYS_FROM_NOW = 0
export const ACTIVATE_ORDERED_CREDIT_MAXIMUM_DAYS_FROM_NOW = 60

export const MAINTENANCE_MODE = false

export enum SPECIAL_OFFER_LAYOUT {
  Discount = 'discount',
  Other = 'other',
}

export enum PRODUCT_LAYOUT {
  Product = 'product',
  ProductAcceptance = 'acceptance',
}

export enum PAYMENT_STATUS {
  Success = 'ok',
  Fail = 'fail',
  Error = 'error',
}

export enum PAYMENT_TYPE {
  BankTransfer = 3,
}

export const COURIER_DELIVERY_IDS = [2, 4]

export const URLS = {
  auth: (redirect: string) => ({ pathname: '/auth', query: { redirect } }),
  contact: ({ scope, hash }: { scope?: string; hash?: string }) => ({
    pathname: `${scope || getCurrentScopeUrl()}/contact`,
    hash,
  }),
  settings: ({
    sub,
  }: {
    sub: 'personalization' | 'admin-management' | 'notifications' | 'company'
  }) => ({
    pathname: `/client/settings/${sub}`,
  }),
  specialOfferDetail: ({ scope, slug }: { scope?: string; slug: string }) => ({
    pathname: `${scope || getCurrentScopeUrl()}/special-offers/detail`,
    query: Object.assign({}, { slug }),
  }),
  productDetail: ({ scope, slug }: { scope?: string; slug: string }) => ({
    pathname: `${scope || getCurrentScopeUrl()}/products/detail`,
    query: Object.assign({}, { slug }),
  }),
  articles: ({ scope, tag }: { scope?: string; tag?: string | string[] }) => ({
    pathname: `${scope || getCurrentScopeUrl()}/articles`,
    query: Object.assign({}, { tag }),
  }),
  articleDetail: ({ scope, slug }: { scope?: string; slug: string }) => ({
    pathname: `${scope || getCurrentScopeUrl()}/articles/detail`,
    query: Object.assign({}, { slug }),
  }),
  tutorialDetail: ({ scope, slug }: { scope?: string; slug: string }) => ({
    pathname: `${scope || getCurrentScopeUrl()}/articles/tutorials/detail`,
    query: Object.assign({}, { slug }),
  }),
  acceptationPlaces: ({ scope }: { scope?: string }) => ({
    pathname: `${scope || getCurrentScopeUrl()}/acceptation-places`,
  }),
  order: (args?: { product?: string | string[] }) => ({
    pathname: '/client/order',
    query: Object.assign({}, { ...(args && args.product && { product: args.product }) }),
  }),
  orderResult: () => ({
    pathname: '/client/order/result',
  }),
  ordersHistory: () => ({
    pathname: '/client/orders-history',
  }),
  ordersHistoryDetail: ({ id }: { id: string }) => ({
    pathname: '/client/orders-history/detail',
    query: Object.assign({}, { id }),
  }),
  productSelection: () => '/client/product-selection',
  employees: () => '/client/dejeuner/cards',
  addresses: () => '/client/addresses-and-departments?tab=addresses',
}

export const PREPROD_HOSTNAMES = ['moje-preprod.up-slovensko.sk', 'stravovanie-preprod.mojeup.sk']
export const DEV_HOSTNAMES = ['moje-dev.up-slovensko.sk']
export const STAGING_HOSTNAMES = ['moje-uat.up-slovensko.sk', 'stravovanie-uat.mojeup.sk']

export const BASE_URLS: {
  cms: string
  uat: string
  uatV2: string
  prod: string
  prodV2: string
} = {
  cms: 'cms.up-slovensko.sk',
  uat: 'moje-uat.up-slovensko.sk',
  uatV2: 'stravovanie-uat.mojeup.sk',
  prod: 'moje.up-slovensko.sk',
  prodV2: 'stravovanie.mojeup.sk',
}

export const LINKS: {
  terms: string
  gdpr: string
  docs: string
  club: string
  complaints: string
  wallet: string
  tutorials: {
    dashboardEndUser: string
    dashboard: string
    signUp: string
  }
  products: {
    shell: {
      acceptationPlaces: string
    }
  }
  contact: {
    map: string
    social: {
      facebook: string
      youtube: string
      linkedin: string
      coop: string
    }
  }
} = {
  terms: 'https://www.up-slovensko.sk/vseobecne-obchodne-podmienky', // looks like a legacy link, doesn't work anymore
  gdpr: 'https://www.up-dejeuner.sk/ochrana-osobnych-udajov',
  docs: 'https://www.up-dejeuner.sk/zamestnavatelia/dokumenty-a-programy',
  club: 'https://www.up-dejeuner.sk/klub-up',
  complaints: 'https://www.up-dejeuner.sk/reklamacny-poriadok',
  wallet: 'https://www.plnapenazenka.sk/?variant=up-sk',
  tutorials: {
    dashboardEndUser:
      'https://cms.up-slovensko.sk/media/2112/1695122393-1685612601-UP_Manual_multizona.pdf',
    dashboard: 'https://cms.up-slovensko.sk/media/2059/1685612601-UP_Manual_multizona.pdf',
    signUp:
      'https://cms.up-slovensko.sk/media/1843/1670422907-1616-20_UpDJ-manual-zakaznicka-zona-registracia_1str_01.pdf',
  },
  products: {
    shell: {
      acceptationPlaces: 'https://www.shell.sk/motoristi/vyhladavanie-cerpacich-stanic.html',
    },
  },
  contact: {
    map:
      'https://www.google.sk/maps/place/Up+Slovensko,+s.+r.+o.+(LE+CHEQUE+DEJEUENER)/@48.164029,17.1498337,17z/data=!3m1!4b1!4m5!3m4!1s0x476c8ed06760a755:0xa309ebf6e16fe0c5!8m2!3d48.164029!4d17.1520224',
    social: {
      facebook: 'https://www.facebook.com/UpSlovensko/',
      youtube: 'https://www.youtube.com/channel/UCS8ZkLCsNF5NeHRBS_N8EMA',
      linkedin: 'https://www.linkedin.com/company/cheque-dejeuner-slovensko/',
      coop: 'http://up-group.coop/',
    },
  },
}
