import React, { HTMLAttributes } from 'react'
import cx from 'classnames'

interface OwnProps {
  className?: string
}

const CLASS_ROOT = 'container'

const Container: React.SFC<HTMLAttributes<HTMLDivElement> | OwnProps> = ({
  className,
  ...other
}) => {
  const classes = cx(CLASS_ROOT, className)

  return <div className={classes} {...other} />
}

export { Container }
