import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import StickyJs from 'sticky-js'

const CLASS_ROOT = 'sticky'

type Props = React.HTMLAttributes<HTMLDivElement>

const Sticky: React.SFC<Props> = ({ className, children, ...other }) => {
  const stickies = useRef<any>(null)
  const id = useRef(
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5)
  )

  useEffect(() => {
    stickies.current = new StickyJs(id.current)
    return () => {
      stickies.current.destroy()
    }
  }, [])

  const classes = cx(CLASS_ROOT, 'align-self-top', className)

  return (
    <div id={id.current} className={classes} data-sticky {...other}>
      {children}
    </div>
  )
}

export { Sticky }
