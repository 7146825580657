import React, { ReactNode, useRef, useEffect, useState, useCallback } from 'react'
import cx from 'classnames'
import { UIOptions } from 'fine-uploader'
import dynamic from 'next/dynamic'
import { Trans, t } from '@lingui/macro'
import { merge } from 'lodash-es'
import { Icon, Bar, BarItem, ButtonLink, Progressbar, Grid, GridCol, Label, InputProps } from '..'
import { clientOnly } from '../../utils/hoc'
import { useForceUpdate } from '../../utils/hooks/useForceUpdate'
import { _, i18n } from '../../utils/intl'
import { ReachTooltip } from '../tooltip'
import { get } from '../../utils/get'

const FileInput = dynamic<any>(() => import('react-fine-uploader/file-input'), { ssr: false })
const Filename = dynamic<any>(() => import('react-fine-uploader/filename'), { ssr: false })
const Thumbnail = dynamic<any>(() => import('react-fine-uploader/thumbnail'), { ssr: false })
const Dropzone = dynamic<any>(() => import('react-fine-uploader/dropzone'), { ssr: false })

const CLASS_ROOT = 'file-upload'

export interface FileUploadOwnProps {
  /** Progressbar visibility */
  hasProgress?: boolean

  /** Preview of uploaded file */
  hasPreview?: boolean

  /** Dropzone for uploading files */
  hasDropzone?: boolean

  /** Fine Uploader options from documentation https://docs.fineuploader.com/branch/master/api/options.html  */
  fineUploaderOptions?: UIOptions

  /** Input label */
  label?: ReactNode

  /** Input size */
  size?: InputProps['inputSize']

  /** Input id */
  id: string

  /** Input button content */
  buttonContent?: ReactNode

  uploaderRef: React.MutableRefObject<any>

  tooltipLabel?: React.ReactNode

  tooltipContent?: React.ReactNode

  onStateChange?(args: { fileId: number | null; progress: number; isInputFocsued: boolean }): void
}

export const FINE_UPLOADER_OPTIONS = {
  debug: true,
  multiple: false,
  autoUpload: false,
}

export const FINE_UPLOADER_IMAGE = {
  acceptFiles: 'image/jpeg,image/png',
  allowedExtensions: ['jpg', 'jpeg', 'png'],
  itemLimit: 1,
  sizeLimit: 1024000, // 1mb
}

export const FINE_UPLOADER_EXCEL = {
  acceptFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  allowedExtensions: ['xlsx'],
}

type FileUploadProps = React.HTMLAttributes<HTMLDivElement> & FileUploadOwnProps

const FileUploadInner = (props: FileUploadProps) => {
  const inputFileElement = useRef<any>(undefined)

  const forceUpdate = useForceUpdate()

  const {
    className,
    hasProgress = true,
    hasPreview = true,
    hasDropzone = true,
    fineUploaderOptions = FINE_UPLOADER_OPTIONS,
    label,
    size,
    id,
    uploaderRef,
    buttonContent = _(t`Vyberte súbor`),
    onStateChange = () => ({}),
    tooltipLabel,
    tooltipContent,
    ...other
  } = props

  const [state, _setState] = useState({
    fileId: null as number | null,
    progress: 0,
    isInputFocsued: false,
    status: '',
  })

  const setState = useCallback(
    (args: typeof state) => {
      _setState(args)
      onStateChange(args)
    },
    [onStateChange, state]
  )

  useEffect(() => {
    import('fine-uploader-wrappers').then(m => {
      const FineUploaderTraditional = m.default

      uploaderRef.current = new FineUploaderTraditional({
        options: merge({}, FINE_UPLOADER_OPTIONS, fineUploaderOptions),
      })

      // helper func for reseting state of uploader from outside
      uploaderRef.current._reset = () => {
        uploaderRef.current.methods.reset()
        setState({
          fileId: null,
          progress: 0,
          isInputFocsued: false,
          status: '',
        })
      }

      uploaderRef.current.on('statusChange', (fileId: any, _oldStatus: any, newStatus: any) => {
        switch (newStatus) {
          case 'rejected':
            setState({ ...state, status: newStatus, fileId, progress: 100 })
            break
          case 'submitted':
            setState({ ...state, fileId, progress: 100 })
            break
          case 'canceled':
          case 'deleted':
            setState({ ...state, fileId: null, progress: 0 })
            break
          default:
            break
        }
      })

      forceUpdate()

      return () => {
        if (uploaderRef.current) {
          uploaderRef.current.off('statusChange')
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDelete = () => {
    const uploader = uploaderRef.current
    uploader.methods.deleteFile(state.fileId)
    setState({ ...state, fileId: null, progress: 0 })
  }

  const { fileId, progress } = state

  const progressMessage = progress < 100 ? i18n.t`${progress} % hotovo` : i18n.t`Dokončené`

  const classes = cx(CLASS_ROOT, className)

  const buttonClasses = cx('btn', {
    [`btn--${size}`]: size,
  })

  if (!uploaderRef.current) return null

  return (
    <div className="form-control form-control--file-upload">
      {label && (
        <Label id={id} size={size === 'huge' ? 'large' : size}>
          {label}
        </Label>
      )}
      <div className={classes} {...other}>
        {uploaderRef.current && (
          <>
            <FileInput
              id={id}
              uploader={uploaderRef.current}
              accept={get(uploaderRef.current.options, 'validation', 'acceptFiles')}>
              <label htmlFor={id} className={buttonClasses} ref={inputFileElement}>
                {buttonContent}
              </label>
            </FileInput>
          </>
        )}
        {tooltipLabel && tooltipContent && (
          <div>
            <ReachTooltip ariaLabel="" label={tooltipLabel as any}>
              {tooltipContent}
            </ReachTooltip>
          </div>
        )}

        {uploaderRef.current &&
          (fileId != null ? (
            <Grid>
              {hasPreview && (
                <GridCol size="shrink">
                  <Thumbnail
                    className={`${CLASS_ROOT}__preview`}
                    id={fileId}
                    uploader={uploaderRef.current}
                  />
                </GridCol>
              )}

              <GridCol size="auto" style={{ overflow: 'auto' }}>
                {state.status === 'rejected' && (
                  <Bar className="mb-small">
                    <BarItem isFilling className="text-ellipsis">
                      <Trans>Nevalidný súbor!</Trans>
                    </BarItem>
                    <BarItem>
                      <ButtonLink
                        equal
                        size="tiny"
                        className="text-color-default"
                        onClick={() => {
                          handleDelete()
                        }}>
                        <Icon name="close" size="medium" />
                      </ButtonLink>
                    </BarItem>
                  </Bar>
                )}

                {state.status !== 'rejected' && (
                  <Bar className="mb-small">
                    <BarItem isFilling className="text-ellipsis">
                      <Filename id={fileId} uploader={uploaderRef.current} />
                    </BarItem>
                    <BarItem>
                      <ButtonLink
                        equal
                        size="tiny"
                        className="text-color-default"
                        onClick={() => {
                          handleDelete()
                        }}>
                        <Icon name="close" size="medium" />
                      </ButtonLink>
                    </BarItem>
                  </Bar>
                )}
                {hasProgress && (
                  <Progressbar
                    className={`${CLASS_ROOT}__progressbar`}
                    progress={progress}
                    message={progressMessage}
                  />
                )}
              </GridCol>
            </Grid>
          ) : (
            hasDropzone && (
              <>
                <Dropzone
                  dropActiveClassName={`${CLASS_ROOT}__dropzone--active`}
                  className={`${CLASS_ROOT}__dropzone align-items-middle align-items-center`}
                  uploader={uploaderRef.current}>
                  <span>
                    <Trans>Presuňte svoje súbory sem</Trans>
                  </span>
                </Dropzone>
              </>
            )
          ))}
      </div>
    </div>
  )
}

export const FileUpload = clientOnly(FileUploadInner)
