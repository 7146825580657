import React from 'react'
import cx from 'classnames'
import { BarItem, BarItemProps } from '../bar/BarItem'

const CLASS_ROOT = 'main-nav__action'

interface MainNavActionProps {
  type?: 'main' | 'user'
  /** Link for the item */
  href?: string
  /** Active item indicator */
  isActive?: boolean
}

type Props = BarItemProps & MainNavActionProps

const MainNavAction: React.SFC<Props> = ({ children, className, ...other }) => {
  const classes = cx(CLASS_ROOT, className)

  return (
    <BarItem className={classes} tag="li" {...other}>
      {children}
    </BarItem>
  )
}

export { MainNavAction }
