function prefixClass(className: string, prefix = '-') {
  return className ? `${`${prefix}${className}`}` : ''
}

export function genClassModsFromString(prefix: string, mod: any) {
  return mod
    .split(' ')
    .map((item: any) => `${prefix}-${item}`)
    .join(' ')
}

export function getClassModsFromObject(prefix: string, mod: object, postfix: string) {
  return Object.keys(mod)
    .map(key => {
      switch (typeof (mod as any)[key]) {
        case 'boolean':
          // prettier inserts new lines and fucks up output
          // prettier-ignore
          return `${prefix}${key !== 'xs' ? `--${key}` : '-'}${prefixClass(postfix)}`;
        case 'string':
        case 'number':
          // prettier inserts new lines and fucks up output
          // prettier-ignore
          return `${prefix}${key !== 'xs' ? `--${key}` : '-'}${prefixClass((mod as any)[key])}${prefixClass(postfix)}`;
        default:
          return null
      }
    })
    .join(' ')
}

export function genResponsiveClasses(prefix: string, mod: any, postfix = '') {
  return [
    typeof mod === 'boolean' ? `${prefix}${prefixClass(postfix, '--')}` : null,
    mod !== null && typeof mod === 'object' ? getClassModsFromObject(prefix, mod, postfix) : null,
    {
      [`${prefix}--${mod}${prefixClass(postfix)}`]:
        typeof mod === 'string' || typeof mod === 'number',
    },
  ]
}

export function getSiblings(el: HTMLElement) {
  if (!(el instanceof Element)) {
    return false
  }

  const parent = el.parentNode
  if (!parent) {
    return false
  }

  const parentChildren: any = []
  const siblings = []

  parentChildren.push.apply(parentChildren, parent.children) // eslint-disable-line prefer-spread

  for (let i = 0, l = parentChildren.length; i < l; i += 1) {
    if (parentChildren[i] !== el) {
      siblings.push(parentChildren[i])
    }
  }

  return siblings
}

export const keyCodes = {
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  ARROWLEFT: 37,
  ARROWUP: 38,
  ARROWRIGHT: 39,
  ARROWDOWN: 40,
}

export function lockBody(
  lock = true,
  container: HTMLElement | null = document.querySelector('#root')
) {
  if (lock) {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    document.body.setAttribute('data-lock-scrolltop', String(scrollTop))

    // add locking styles to body
    document.body.style.height = '100%'
    document.body.style.width = '100%'
    document.body.style.overflow = 'hidden'
    document.body.style.position = 'fixed'

    // add locking styles to scrollTop
    if (container) {
      /* eslint-disable no-param-reassign */
      container.style.height = '100%'
      container.style.width = '100%'
      container.style.overflow = 'hidden'
      container.style.position = 'fixed'
      // scroll page-container to scrollTop position
      container.scrollTop = scrollTop
      /* eslint-enable no-param-reassign */
    }

    // attempt to scroll top fixed position
    window.requestAnimationFrame(() => {
      window.scrollTo(0, scrollTop)
    })

    return true
  }

  const scrollTop = document.body.getAttribute('data-lock-scrolltop')

  // remove locking styles from body
  document.body.style.height = ''
  document.body.style.width = ''
  document.body.style.overflow = ''
  document.body.style.position = ''
  // set scroll position back

  // remove locking styles from page-container
  if (container) {
    /* eslint-disable no-param-reassign */
    container.style.height = ''
    container.style.width = ''
    container.style.overflow = ''
    container.style.position = ''
    /* eslint-enable no-param-reassign */
  }

  window.scrollTo(0, Number(scrollTop))

  return false
}
