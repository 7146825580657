import React from 'react'
import cx from 'classnames'
import { Icon } from '../../icon/Icon'

export interface MessageOwnProps {
  type?: 'error' | 'help' | 'success' | 'info'

  icon?: string

  children: React.ReactNode
}

type Props = React.HTMLAttributes<HTMLParagraphElement> & MessageOwnProps

const CLASS_ROOT = 'form-message'

const Message: React.FC<Props> = ({ className, children, type, icon, ...other }) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${type}`]: type,
    },
    className
  )

  let iconName = icon

  if (!iconName && type) {
    iconName = `message-${type}`
  }

  return (
    <p className={classes} {...other}>
      {iconName && <Icon name={iconName} size="small" className={`${CLASS_ROOT}__icon`} />}
      <span className={`${CLASS_ROOT}__text`}>{children}</span>
    </p>
  )
}

export { Message }
