import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'card__content'

interface CardContentProps {
  className?: string
  fill?: boolean
  /** Background color for card setion */
  color?: string
  /** Amount of padding applied */
  size?: 'space' | 'condensed'
}

type Props = React.HTMLAttributes<HTMLDivElement> & CardContentProps

const CardContent: React.SFC<Props> = ({ className, fill, color, size, ...other }) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--fill`]: fill,
      [`${CLASS_ROOT}--${color}`]: color,
      [`${CLASS_ROOT}--${size}`]: size,
    },
    className
  )

  return <div className={classes} {...other} />
}

export { CardContent }
