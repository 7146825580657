/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, RefObject } from 'react'

export function useSessionClose(closeButtonRef: RefObject<any>, id: string) {
  const [opened, setOpened] = useState(false)

  const noticeNameInsideSessionStorage = `notice#${id}`

  const handleClick = () => {
    sessionStorage.setItem(noticeNameInsideSessionStorage, JSON.stringify(false))
    setOpened(false)
  }

  useEffect(() => {
    const ref = closeButtonRef.current
    if (ref) {
      ref.addEventListener('click', handleClick)
    }

    try {
      if (JSON.parse(String(sessionStorage.getItem(noticeNameInsideSessionStorage))) === false) {
        setOpened(false)
      } else {
        setOpened(true)
      }
    } catch (e) {
      setOpened(true)
    }

    return () => {
      if (ref) {
        ref.removeEventListener('click', handleClick)
      }
    }
  }, [opened])

  return opened
}
