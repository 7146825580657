import React from 'react'

interface CardImageHeaderProps {
  children?: React.ReactNode
  className?: string
  bgUrl?: string
}
const CLASS_ROOT = 'card__header--image'

type Props = React.HTMLAttributes<HTMLDivElement> & CardImageHeaderProps

export const CardHeaderImage: React.SFC<Props> = ({ children, bgUrl, ...other }) => {
  return (
    <div className={CLASS_ROOT} style={{ backgroundImage: `url(${bgUrl})` }} {...other}>
      {children}
    </div>
  )
}
