import { User, UserAccess } from '@odiupsk/up-api-client'
import { action, observable } from 'mobx'
import { SCOPES } from '../config/constants'

import { Auth } from './modules/auth/Auth'
import { maxPerPage } from './utils/maxPerPage'
import { usersApi } from './api'

const LAST_ENTITY_ID_LOCAL_STORAGE_KEY = 'context.lastEntityId' // what client/end-user id was last active, so that we can continue next time

export class AppStore {
  @observable currentUser?: User = undefined

  @observable currentUserLoading = true

  @observable currentUserAccesses: UserAccess[] = []

  @observable currentRole: SCOPES = SCOPES.Public

  @observable isLoggedIn?: boolean

  @observable is404 = false

  @action async fetchLoggedInUser(forceUpdate = false) {
    if (this.currentUser && !forceUpdate) {
      return
    }

    const userId = Auth.getUserId()
    const userToken = Auth.getAccessToken()

    if (!userId) {
      throw new Error('userId missing')
    }

    this.currentUserLoading = true

    const userResponse = await usersApi.getUser(userId)

    if (!userResponse.data) return
    this.currentUser = userResponse.data
    this.currentUserLoading = false

    if (!Auth.forceChangeEmail(userToken!)) {
      const userAccesses = await usersApi.getUserAccesses(userId, {
        params: { per_page: maxPerPage },
      })
      this.currentUserAccesses = userAccesses.data.data!
    }
  }

  reloadUser = async () => {
    return this.fetchLoggedInUser(true)
  }

  saveLastEntityIdToLocalStorage = (id: string) => {
    localStorage.setItem(LAST_ENTITY_ID_LOCAL_STORAGE_KEY, id)
  }

  getLastEntityIdFromLocalStorage = () => {
    return localStorage.getItem(LAST_ENTITY_ID_LOCAL_STORAGE_KEY)
  }

  getIdInScope(scope: SCOPES) {
    const access = this.currentUserAccesses.find(i => i.scope === scope)
    return access && access.scope_object_id
  }

  hasIdInSomeScope(scopes: SCOPES[]) {
    const access = this.currentUserAccesses.some(i => scopes.includes(i.scope))
    return access
  }

  hasIdInAccesses(id: string) {
    return this.currentUserAccesses.some(access => access.scope_object_id === id)
  }

  getScopeFromId(id: string) {
    const userAccess = this.currentUserAccesses.find(access => access.scope_object_id === id)

    if (userAccess) {
      return userAccess.scope
    }
  }

  getUserAccessFromId(id: string) {
    return this.currentUserAccesses.find(access => access.scope_object_id === id)
  }

  getIsUserMasterFromId(id: string): boolean | undefined {
    const userAccess = this.currentUserAccesses.find(access => access.scope_object_id === id)
    return userAccess && userAccess.master
  }
}

export const appStore = new AppStore()
