import React from 'react'
import cx from 'classnames'
import { genResponsiveClasses } from '../utils'

type Direction = 'horizontal' | 'vertical'

interface ButtonLayoutsOwnProps {
  /** ButtonLayouts component is used for creating ButtonGroup and also Button */
  type?: 'group' | 'layout'
  /**
    Layout direction. Stack accepts enum value or media object
     `{xl: [direction], l: [direction], m: [direction], s: [direction], xs: [direction]}`.
  */
  direction?:
    | Direction
    | {
        xl?: Direction
        l?: Direction
        m?: Direction
        s?: Direction
        xs?: Direction
      }
}

export type ButtonLayoutsProps = React.HTMLAttributes<HTMLDivElement> & ButtonLayoutsOwnProps

const ButtonLayouts: React.SFC<ButtonLayoutsProps> = ({
  className,
  direction = 'vertical',
  type,
  ...other
}) => {
  let ButtonLayoutDirection = direction
  if (typeof direction === 'object') {
    ButtonLayoutDirection = { ...{ xs: 'vertical' }, ...direction }
  }

  const classes = cx(
    `btn-${type}`,
    ...genResponsiveClasses(`btn-${type}`, ButtonLayoutDirection),
    className
  )

  return <div className={classes} {...other} />
}

export { ButtonLayouts }
