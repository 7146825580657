import React from 'react'
import cx from 'classnames'

interface DropdownItemOwnProps {
  /** HTML tag of the menu item. Remember, anchors take you elsewhere while buttons do stuff */
  tag?: string
  className?: string
  href?: string
}

const CLASS_ROOT = 'dropdown__item'

type DropdownItemProps = React.HTMLAttributes<HTMLElement> & DropdownItemOwnProps

const DropdownItem: React.SFC<DropdownItemProps> = ({
  className,
  children,
  tag = 'button',
  ...other
}) => {
  const classes = cx(CLASS_ROOT, className)
  const Tag = tag
  let type = ''
  if (Tag === 'button') {
    type = 'button'
  }

  return React.createElement(
    Tag,
    { role: 'menuitem', className: classes, type, ...other },
    children
  )
}

export { DropdownItem }
