import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'progressbar'

interface ProgressbarProps {
  className?: string
  progress?: number
  message?: string
  radius?: 'sharp'
}

type Props = React.HTMLAttributes<HTMLDivElement> & ProgressbarProps

const Progressbar: React.SFC<Props> = ({ className, progress = 0, message, radius, ...other }) => {
  const classes = cx(CLASS_ROOT, className, {
    [`${CLASS_ROOT}--${radius}`]: radius,
  })

  return (
    <React.Fragment>
      <div className={classes} {...other}>
        <div className={`${CLASS_ROOT}__fill`} style={{ width: `${progress}%` }} />
      </div>
      <div className="text-color-grey-600">{message}</div>
    </React.Fragment>
  )
}

export { Progressbar }
