import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'dialog__content'

export type DialogContentProps = React.HTMLAttributes<HTMLDivElement>

const DialogContent: React.SFC<DialogContentProps> = ({ className, ...other }) => {
  const classes = cx(CLASS_ROOT, className)

  return <div className={classes} {...other} aria-label="dialog-content" />
}

export { DialogContent }
