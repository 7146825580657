import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'list'

interface ListOwnProps {
  className?: string
  /** List with outer border */
  bordered?: boolean
  /** List without any borders(on <ul/> and <li/> elements) */
  clean?: boolean
  /** List with smaller spacing between items */
  space?: 'condensed' | 'condensed-v'
  children?: React.ReactNode
}

export type ListProps = React.HTMLAttributes<HTMLUListElement> & ListOwnProps

const List: React.SFC<ListProps> = ({ className, bordered, clean, space, children, ...other }) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--bordered`]: bordered,
      [`${CLASS_ROOT}--clean`]: clean,
      [`${CLASS_ROOT}--${space}`]: space,
    },
    className
  )

  return (
    <ul className={classes} {...other}>
      {children}
    </ul>
  )
}

export { List }
