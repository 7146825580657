import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'section'

interface SectionProps {
  className?: string
  /** Section size (top and bottom padding) */
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'huge' | 'humongous'
  /** Background color */
  color?: 'light-grey' | 'black' | 'turquoise' | 'white'
}

type Props = React.HTMLAttributes<HTMLDivElement> & SectionProps

const Section: React.SFC<Props> = ({ className, size, color, ...other }) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${size}`]: size,
      [`${CLASS_ROOT}--${color}`]: color,
    },
    className
  )

  return <div className={classes} {...other} />
}

export { Section }
