import React, { RefObject } from 'react'
import cx from 'classnames'
import { Loader, LoaderProps } from '../loader'

const CLASS_ROOT = 'btn'

export type ButtonSizes = 'tiny' | 'small' | 'large' | 'huge'
export type ButtonTypes = 'primary' | 'secondary' | 'link' | 'link-secondary' | 'hollow'

export interface ButtonOwnProps {
  /** Button with same width as height. */
  equal?: boolean
  /** Link with href attribute rendered as button. */
  href?: string
  /** Link with target attribute rendered as button. */
  target?: string
  /** Possible positions of icon inside button. */
  iconPosition?: 'left' | 'right'
  /** Active state. */
  isActive?: boolean
  /** Disabled state */
  isDisabled?: boolean
  /** Loading state */
  isLoading?: boolean
  /** Loader props */
  loaderProps?: LoaderProps
  /** Button sizes */
  size?: ButtonSizes
  /** Button horizontal space. */
  space?: 'condensed' | 'nospace'
  /** Button can take one of possible shapes. */
  shape?: 'radius' | 'round'
  /** Button(input) with `type="submit"`. */
  submit?: boolean
  /** Html tag in which is button rendered. */
  tag?: keyof React.ReactHTML
  /** Visual type of button. */
  type?: ButtonTypes
  /** Forwarded DOM element ref. */
  elemRef?: RefObject<any>
  className?: string
  children?: React.ReactNode
  spaceBetween?: boolean
}

export type ButtonProps = React.HTMLAttributes<
  HTMLInputElement | HTMLButtonElement | HTMLAnchorElement
> &
  ButtonOwnProps

const Button: React.SFC<ButtonProps> = ({
  className,
  children,
  equal,
  href,
  iconPosition,
  isActive,
  isDisabled = false,
  isLoading = false,
  loaderProps,
  size,
  space,
  shape,
  submit,
  tag = 'button',
  type,
  elemRef,
  spaceBetween,
  ...other
}) => {
  let Tag = tag
  let buttonType: string | null = submit ? 'submit' : 'button'
  let buttonIsDisabled: boolean | null = isDisabled
  let content: ButtonOwnProps['children'] = children
  const loaderPropsMergedWithDefault: LoaderProps = {
    // isCentered: true,
    white: true,
    size: 'small',
    loaderWrapperProps: {
      style: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '.5rem',
      },
    },
    ...loaderProps,
  }
  const buttonIconPosition = iconPosition || null

  if (href) {
    Tag = 'a'
    buttonType = null
  }

  if (Tag === 'a') {
    buttonType = null
  }

  if (isLoading) {
    content = (
      <>
        <Loader {...loaderPropsMergedWithDefault} /> {content}
      </>
    )
  }

  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--equal`]: equal,
      [`${CLASS_ROOT}--${size}`]: size,
      [`${CLASS_ROOT}--${space}`]: space,
      [`${CLASS_ROOT}--${shape}`]: shape,
      [`${CLASS_ROOT}--${type}`]: type,
      [`${CLASS_ROOT}--icon-${buttonIconPosition}`]: buttonIconPosition,
      [`${CLASS_ROOT}--space-between`]: spaceBetween,
      'is-active': isActive,
      'is-disabled': Tag === 'a' && isDisabled,
    },
    className
  )

  const onClick = other.onClick

  other.onClick = event => {
    if (document.activeElement) {
      try {
        ;(document.activeElement as HTMLElement).blur()
      } catch (error) {
        console.log(error)
      }
    }

    if (onClick) {
      onClick(event)
    }
  }

  if (isDisabled && Tag === 'a') {
    buttonIsDisabled = null
  }

  const props = {
    disabled: buttonIsDisabled,
    href,
    type: !href ? buttonType : null,
    className: classes,
    ref: elemRef,
    ...other,
  }

  return React.createElement(Tag, { ...props }, content)
}

export { Button }
