import Router from 'next/router'
import { SCOPES } from '../../../config/constants'
import { appStore } from '../../AppStore'

/**
 * returns parent url of current pathname
 */
export const back = () => {
  const segments = Router.pathname.split('/')
  return segments.slice(0, segments.length - 1).join('/')
}

export const backWithPathname = (pathname: string) => {
  const path = Router.pathname.split('/')
  path.splice(-1, 1, pathname).join('/')

  return path.join('/')
}

/**
 *  @deprecated
 */
export const getUrlFragmentFromScope = (scope?: SCOPES) => {
  let url

  switch (scope) {
    case SCOPES.Client:
      url = '/client'
      break
    case SCOPES.Holder:
      url = '/end-user'
      break
    default:
      url = ''
  }

  return url
}

export const getCurrentScopeUrl = () => {
  const { currentRole } = appStore
  let url

  switch (currentRole) {
    case SCOPES.Client:
      url = '/client'
      break
    case SCOPES.Holder:
      url = '/end-user'
      break
    default:
      url = ''
  }

  return url
}

/**
 * if search param is array, returns only first value
 */
export const getURLSearchParamNoArray = (param?: string | string[]) => {
  if (param == null) return undefined
  if (Array.isArray(param)) return param[0]
  return param
}

export const getSearchParam = getURLSearchParamNoArray
