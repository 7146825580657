import { TOGGLE_EVT } from './Toggle'
// import lockBody from './../lib/lockBody';

const lockBody = (..._args: any[]) => {
  // eslint-disable-next-line no-console
  console.warn('not implemented')
}

export default function toggle(props: any) {
  const { element, attribute, value, dispatchEvent = true, eventDetail } = props
  let returnValue = null

  if (!element || !(element instanceof Element)) {
    return returnValue
  }

  if (attribute === 'class') {
    element.classList.toggle(value)
    returnValue = value
  }

  if (attribute === 'text') {
    const oldText = element.textContent
    element.textContent = value
    returnValue = oldText
  }

  if (attribute === 'lock-body') {
    if (!value) {
      return lockBody(true)
    }
    return lockBody(false)
  }

  if (attribute !== 'class' && attribute !== 'text' && attribute !== 'lock-body') {
    returnValue = element.getAttribute(attribute)

    if (!value) {
      element.setAttribute(attribute, returnValue === 'true' ? 'false' : 'true')
    } else {
      element.setAttribute(attribute, value)
    }
  }

  if (dispatchEvent) {
    element.dispatchEvent(
      new CustomEvent(TOGGLE_EVT, {
        detail: eventDetail,
        bubbles: true,
      })
    )
  }

  return returnValue
}
