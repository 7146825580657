import React from 'react'
import cx from 'classnames'
import { genResponsiveClasses } from '../utils'

type Size = 'shrink' | 'fill' | 'auto' | 'fixed'

export interface GridColOwnProps {
  className?: string

  /** Html element. */
  tag?: keyof React.ReactHTML

  /** Column size. Number, 'shrink/fill/auto', or responsive map */
  size?:
    | number
    | Size
    | {
        xl?: number | Size
        l?: number | Size
        m?: number | Size
        s?: number | Size
        xs?: number | Size
      }

  equalizeHeight?: boolean
}

type GridColProps = React.HTMLAttributes<HTMLDivElement> & GridColOwnProps

const CLASS_ROOT = 'grid__col'

const GridCol: React.SFC<GridColProps> = ({
  className,
  tag = 'div',
  size,
  equalizeHeight,
  ...other
}) => {
  const classes = cx(
    !size && CLASS_ROOT,
    ...genResponsiveClasses(CLASS_ROOT, size),
    {
      [`${CLASS_ROOT}--equalized`]: equalizeHeight,
    },
    className
  )

  const TagName = tag

  return React.createElement(TagName, { className: classes, ...other })
}

export { GridCol }
