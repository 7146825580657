import React from 'react'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { BarItem } from '../bar/BarItem'

const CLASS_ROOT = 'mobile-nav'

interface MobileNavItemProps {
  /** Text in the item */
  itemTitle: string
  /** Link for the item */
  href: string
  /** Active item indicator */
  isActive?: boolean
}

type Props = React.HTMLAttributes<HTMLAnchorElement> & MobileNavItemProps

const MobileNavItem = ({ itemTitle, href, isActive, className, ...other }: Props) => {
  const router = useRouter()
  const classes = cx(
    `${CLASS_ROOT}__item`,
    {
      'is-active': isActive || (router ? router.pathname === href : false),
    },
    className
  )

  return (
    <BarItem tag="li">
      <Link href={href}>
        <a className={classes} {...other}>
          <span className={`${CLASS_ROOT}__item-control`}>{itemTitle}</span>
        </a>
      </Link>
    </BarItem>
  )
}

export { MobileNavItem }
