import { alertsStore } from '../ui-kit'
import { extractMessageFromAxiosError } from '../utils/extractMessageFromAxiosError'

export function logError(err: Error, message?: string) {
  // TODO: add bugnsag log
  if (localStorage.getItem('logEnabled')) {
    // eslint-disable-next-line no-console
    console.error(new Error().stack!.split('\n')[2].trim(), err)
  }

  alertsStore.create({
    content: extractMessageFromAxiosError(err) ?? message,
    timeout: 6000,
    icon: 'message-error',
    type: 'error',
  })
}

export function logSuccess(message: string) {
  alertsStore.create({
    content: message,
    timeout: 6000,
    icon: 'message-success',
    type: 'success',
  })
}
