import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'card__footer'

interface CardFooterProps {
  className?: string
  /** Footer with no borders */
  clean?: boolean
  /** Amount of padding applied */
  size?: 'space' | 'condensed'
}

type Props = React.HTMLAttributes<HTMLDivElement> & CardFooterProps

const CardFooter: React.SFC<Props> = ({ className, clean = false, size, ...other }) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--clean`]: clean,
      [`${CLASS_ROOT}--${size}`]: size,
    },
    className
  )

  return <div className={classes} {...other} />
}

export { CardFooter }
