import { t } from '@lingui/macro'
import { _ } from '../../utils/intl'

const LOCATION_ROW_BASE = 'location-item-'

const API_KEY = 'AIzaSyDGe5vjL8wBmilLzoJ0jNIwe9SAuH2xS_0' // TODO: doplnit funkcny api key

const defaultConfig = {
  center: { lat: 48.14816, lng: 17.10674 },
  zoom: 12,
}

export class MapStatic {
  locations: any

  element: any

  config: any

  sidebar: any

  map: any

  maps: any

  markers: any = []

  historyPosition: any

  instance: any

  infoWindow: any

  googleMapsLoader: any

  constructor(googleMapsLoader: any, id: string, locations: {}[], config: object) {
    this.googleMapsLoader = googleMapsLoader
    this.locations = locations
    this.element = document.getElementById(id)
    this.config = { ...defaultConfig, ...config }
    this.init()

    return this
  }

  init() {
    this.googleMapsLoader.KEY = API_KEY
    // this.googleMapsLoader.LIBRARIES = ['places'];
    this.googleMapsLoader.LANGUAGE = 'sk'
    this.googleMapsLoader.REGION = 'SK'

    this.googleMapsLoader.load((google: any) => {
      this.element.innerHTML = `
        <div class="map__sidebar"></div>
        <div class="map__content"></div>
      `
      this.map = new google.maps.Map(this.element.querySelector('.map__content'), this.config)

      let itemsCount = 0
      if (this.locations && this.locations.length > 0) {
        itemsCount = this.locations.length

        this.locations.map((location: any) => this.createMarker(location, this.map))
      }

      const title = `${_(t`Počet nájdených prevádzok`)}: ${itemsCount}`
      this.createSidebar(title, this.locations)
      this.element.querySelector('.map__sidebar').appendChild(this.sidebar)
    })

    this.googleMapsLoader.onLoad((google: any) => {
      this.maps = google.maps
      this.infoWindow = new this.maps.InfoWindow({
        maxWidth: 200,
      })

      if (this.locations && this.locations.length > 0) {
        this.locations.map((location: any) => this.createMarker(location, this.map))
      } else if (navigator.geolocation) {
        // Try HTML5 geolocation.
        navigator.geolocation.getCurrentPosition(
          position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }

            this.setCurrentPosition(pos)
          },
          () => {
            this.handleLocationError(true)
          }
        )
      } else {
        // Browser doesn't support Geolocation
        this.handleLocationError(false)
      }
    })
  }

  createSidebar(title: any, locations: any) {
    this.sidebar = document.createElement('div')
    this.sidebar.className = 'map__sidebar__content'

    const header = document.createElement('div')
    header.className = 'map__sidebar__header'
    header.innerHTML = title

    const list = document.createElement('ul')
    list.className = 'map__sidebar__list list list--unstyled list--bordered'

    if (locations && locations.length) {
      locations.forEach((location: any) => {
        const row = document.createElement('li')
        row.id = LOCATION_ROW_BASE + location.id
        row.className = 'list__item list__item--clickable'
        row.innerHTML = `
          <div class="bar bar--horizontal mb-tiny">
            <div class="bar__item bar__item--fill">
              <strong>${location.title}</strong>
            </div>
            <div class="bar__item bar__item--shrink text-color-grey-600">
              ${location.distance || ''}
            </div>
          </div>
          <div class="bar bar--horizontal mb-tiny bar--nowrap">
            <div class="bar__item bar__item--fill">
              ${location.address}
            </div>
            <div class="bar__item text-color-theme-500">
              <svg
                class="icon icon--medium text-vam"
                role="presentation"
              >
                <use xlink:href="/static/sprite.svg#chevron-right" />
              </svg>
            </div>
          </div>
          <div class="bar bar--horizontal-small mb-none">
            ${location.tags &&
              location.tags.reduce(
                (accumulator: any, tag: any) =>
                  `${accumulator}
                  <div class="bar__item">
                    <div class="badge badge--small">${tag}</div>
                  </div>`,
                ''
              )}
          </div>
        `

        list.appendChild(row)

        row.addEventListener('mouseenter', () => {
          this.markers.forEach((marker: any) => {
            if (marker.id === location.id) {
              marker.setVisible(false)
            }
          })
        })

        row.addEventListener('mouseleave', () => {
          this.markers.forEach((marker: any) => {
            marker.setVisible(true)
          })
        })
      })
    }

    this.sidebar.appendChild(header)
    this.sidebar.appendChild(list)
  }

  handleLocationError(browserHasGeolocation: any) {
    this.infoWindow.setPosition(this.map.getCenter())
    this.infoWindow.setContent(
      browserHasGeolocation
        ? _(t`Error: The Geolocation service failed.`)
        : _(t`Error: Your browser doesn't support geolocation.`)
    )
    this.infoWindow.open(this.map)

    const request = {
      name: 'Up Slovensko',
      location: this.config.center,
      radius: '500',
    }

    const service = new this.maps.places.PlacesService(this.map)
    service.nearbySearch(request, (results: any, status: any) => {
      if (status === this.maps.places.PlacesServiceStatus.OK) {
        results.map((result: any) => this.createMarkerFromPlace(result))
      }
    })
  }

  setCurrentPosition(pos: any) {
    this.map.setCenter(pos)
  }

  getCurrentPosition() {
    this.map.getCenter()
  }

  createMarker(location: any, map: any) {
    const marker = new this.maps.Marker({
      title: location.title,
      position: new this.maps.LatLng(location.lat, location.lon),
      icon: '/static/img/map-pin.svg',
      map,
    })

    marker.id = location.id
    this.markers.push(marker)

    const searchLink = 'https://www.donappetit.sk/restaurant/search/advanced?search_query='

    const content = `<div>
        <div>
          <h5>${location.title}</h5>
        </div>
        <div class="text-center">
            <strong>
                <a 
                    class="link"
                    href="${searchLink}${location.title.split(' ').join('+')}"
                >
                    Pozrieť na Donappetit.sk
                </a>
            </strong>
        </div>
    </div>`

    marker.addListener('click', () => {
      const info = this.infoWindow
      if (info.getContent() && info.getContent().length && marker.position === info.position) {
        info.setContent('')
        info.close()
      } else {
        info.setContent(content)
        info.open(this.map, marker)
      }
    })
  }

  createMarkerFromPlace(place: any) {
    const marker = new this.maps.Marker({
      map: this.map,
      position: place.geometry.location,
    })

    marker.addListener(marker, 'click', () => {
      this.infoWindow.setContent(place.name)
      this.infoWindow.open(this.map, marker)
    })
  }

  update() {
    this.instance.update()
  }

  destroy() {
    this.googleMapsLoader.release(() => {
      // console.log('No google maps api around');
    })
    this.instance = null
  }
}
