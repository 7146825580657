import React from 'react'
import cx from 'classnames'
import { DialogContentProps } from './DialogContent'

const CLASS_ROOT = 'dialog__footer'

export const DialogFooter: React.SFC<DialogContentProps> = ({ children, className, ...other }) => {
  const classes = cx(CLASS_ROOT, className)
  return (
    <div className={classes} {...other}>
      {children}
    </div>
  )
}
