import { AxiosError, AxiosResponse } from 'axios'
import { forEach, upperFirst, camelCase, values } from 'lodash-es'
import { Result } from '@odiupsk/up-api-client'
import { FieldState } from 'formstate'
import { DeepPartial } from 'ts-essentials'
import { get } from './get'
import { logError } from '../api/utils'

export const extractMessageFromAxiosError = (_err: any) => {
  const err = _err as AxiosError
  if (get(err.response, 'data', 'Message')) return get(err.response, 'data', 'Message')
  return get(err.response, 'data', 'messages', 0, 'message')
}

export const extractAllMessagesFromAxiosError = (_err: any) => {
  const err = _err as AxiosError
  if (get(err.response, 'data', 'Message')) return get(err.response, 'data', 'Message')
  return get(err.response, 'data', 'messages')
}

export const extractMessageFromAxiosResponse = (res: AxiosResponse<any>) => {
  try {
    return res.data.Message ?? res.data.messages[0].message
  } catch (err) {
    console.warn(`Can't parse message`, res)
  }
}

export const extractFieldErrorFromAxiosError = (_err: any) => {
  const err = _err as AxiosError
  const messages: NonNullable<Result['messages']> = get(err.response, 'data', 'messages') || []

  const errors: Record<string, string> = {}
  messages.forEach(m => {
    if (m.field) {
      errors[m.field] = m.message || ''
    }
  })
  return errors
}

/**
 * iterates over errors from server & calls setError on corresponding fields.
 *
 * PS: requires type parameter - typicaly type of body payload of request.
 * This is used to validate fields type against body (and thus response errors) type
 *
 * ```
 * setErrorsFromResult<{ oldPass: string; newPass: string }>(this.passwordForm.fields, err)
 * ```
 * @param fields - map of body field and coresponding form field
 * @param err - AxiosError
 * @returns boolean - whether at least 1 field error was found
 */

type ErrorOptions = {
  showNotice: boolean
}
export function setErrorsFromResult<T = void>(
  fields: DeepPartial<
    Record<
      T extends void ? 'TYPE ERROR: You must provide a type parameter' : keyof T,
      FieldState<any>
    >
  >,
  err: Error,
  options: ErrorOptions = { showNotice: true }
) {
  const defaultOptions: ErrorOptions = { showNotice: true }
  const config: ErrorOptions = { ...defaultOptions, ...options }

  const errors = extractFieldErrorFromAxiosError(err)

  forEach(fields, (val, key) => {
    // try to be little defensive when looking for key
    const upperCasedKey = upperFirst(camelCase(key))

    if (errors[key]) (val as FieldState<any>).setError(errors[key])
    if (errors[upperCasedKey]) (val as FieldState<any>).setError(errors[upperCasedKey])
  })

  if (config.showNotice && values(errors).length) {
    logError(err)
  }
}
