import React, { useState, useEffect, useRef, useCallback, memo } from 'react'
import cx from 'classnames'
import { deburr } from 'lodash-es'
import { MessageOwnProps, Message } from '../message/Message'
import { Label } from '../label/Label'

const CLASS_ROOT = 'select'
const CLASS_CHOICES = 'choices'
const CLASS_CHOICES_LIST = 'choices__list'
const CLASS_CHOICES_ITEM = 'choices__item'
const CLASS_CHOICES_INPUT = 'choices__input'

interface SelectOwnProps {
  messages?: {
    type: MessageOwnProps['type']
    text: React.ReactNode
    icon?: string
    iconSpritePath?: string
  }[]
  /** Html id attribute. */
  id?: string

  /** Select can be cleared to default value. */
  isClearable?: boolean

  /** Disabled state. */
  isDisabled?: boolean

  /** Select option can be specfied via children or as options array */
  options?: Array<{ text: string; value: string }>

  /** Label text. */
  label?: string | React.ReactNode

  /** Custom label renderer. Passes select props as function parameter. */
  renderLabel?: Function

  showSearch?: boolean

  /** Size of element. */
  size?: 'small' | 'large' | 'huge'

  /** Select value. */
  value?: string

  defaultValue?: string

  /** Required field */
  isRequired?: boolean

  /** Select has error */
  error?: boolean

  /** Transparent style */
  transparent?: boolean

  onChange?: (arg: string) => void

  dropdownStyles?: React.CSSProperties
}

export type SelectProps = Omit<React.HTMLAttributes<{}>, 'onChange' | 'defaultValue'> &
  SelectOwnProps

const Select: React.SFC<SelectProps> = memo(props => {
  const {
    className,
    messages = [],
    label,
    renderLabel,
    options = [],
    size,
    id,
    isDisabled,
    isClearable,
    // FIXME: value vs defaultValue handling
    value,
    defaultValue,
    isRequired,
    showSearch,
    transparent,
    error,
    dropdownStyles,
    placeholder,
  } = props

  if (label) {
    // eslint-disable-next-line no-console
    console.assert(id, 'If label is set, also id has to be')
  }

  // eslint-disable-next-line no-console
  // log('re-rendered', id)

  let mergedOptions = options

  const [selected, setSelected] = useState(() =>
    options.find(i => (defaultValue != null ? defaultValue === i.value : false))
  )

  const [isOpen, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)

  const selectedValue = value != null ? options.find(i => value === i.value) : selected

  if (searchTerm !== '') {
    mergedOptions = mergedOptions.filter(i =>
      deburr(i.text.toLowerCase()).includes(deburr(searchTerm.toLowerCase()))
    )
  }

  const onChange = (arg: { text: string; value: string }) => {
    if (value == null || !value) {
      setSelected(arg)
    }
    // eslint-disable-next-line no-unused-expressions
    props.onChange && props.onChange(arg.value)
  }

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (ref.current && ref.current.contains(e.target as Node)) return
    setOpen(false)
    setSearchTerm('')
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false)
    }
  }, [handleClickOutside])

  useEffect(() => {
    if (isOpen && searchRef.current) {
      setTimeout(() => searchRef.current && searchRef.current.focus(), 100)
    }
  }, [isOpen])

  const isError = error || messages.some(m => m.type === 'error')

  const classes = cx(
    CLASS_CHOICES,
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${size}`]: size,
      [`${CLASS_ROOT}--transparent`]: transparent,
      'is-error': isError,
      'is-open': isOpen,
      'is-disabled': isDisabled,
    },
    className
  )

  const formControlClasses = cx('form-control', 'form-control--select')

  const selectLabel =
    (renderLabel && renderLabel(props)) ||
    (label && (
      <Label id={id} size={size === 'huge' ? 'large' : size} isError={isError}>
        {label}
        {isRequired && <span className="form-label__required">&nbsp;*</span>}
      </Label>
    ))

  const selectOptions = mergedOptions.map(option => (
    <div
      key={option.value}
      data-choice=""
      data-choice-selectable=""
      data-testid="option"
      className={cx(
        CLASS_CHOICES_ITEM,
        `${CLASS_CHOICES_ITEM}--choice`,
        `${CLASS_CHOICES_ITEM}--selectable`,
        selectedValue && selectedValue.value === option.value && 'is-highlighted'
      )}
      onKeyDown={_ => ({})}
      onClick={_ => {
        setOpen(false)
        onChange(option)
      }}>
      {option.text}
    </div>
  ))

  const messagesOutput = messages.map(m => (
    <Message key={m.type} type={m.type} icon={m.icon}>
      {m.text}
    </Message>
  ))

  return (
    <div className={formControlClasses}>
      {selectLabel}
      <div
        ref={ref}
        className={classes}
        role="listbox"
        tabIndex={0}
        aria-haspopup="true"
        aria-expanded="false"
        dir="ltr"
        aria-activedescendant="choices-select-basic-item-choice-1"
        data-type="select-one"
        id={id}>
        <div
          className="choices__inner"
          onClick={_ => {
            if (isDisabled) return
            setOpen(!isOpen)
          }}
          onKeyDown={_ => ({})}>
          <div className={cx(CLASS_CHOICES_LIST, `${CLASS_CHOICES_LIST}--single`)}>
            {selectedValue && (
              <div
                className={cx(CLASS_CHOICES_ITEM, `${CLASS_CHOICES_ITEM}--selectable`)}
                aria-selected="true"
                data-item=""
                data-id="1"
                data-value="branch-all"
                data-testid="selected-value">
                {selectedValue.text}
              </div>
            )}
            {!selectedValue && (
              <div style={{ fontSize: 15, color: 'rgb(148, 148, 148)' }}>{placeholder}</div>
            )}
          </div>
          {selectedValue && (
            <button
              type="button"
              style={{ visibility: isClearable ? 'visible' : 'hidden' }}
              className={`${CLASS_CHOICES}__button`}
              data-button=""
              onClick={e => {
                // FIXME: using undefined values would be better (conflicts with formstate)
                onChange({ text: '', value: '' })
                e.stopPropagation()
              }}
              aria-label={`Remove item: '${selectedValue.text}'`}>
              Remove item
            </button>
          )}
        </div>
        <div
          className={cx(
            CLASS_CHOICES_LIST,
            `${CLASS_CHOICES_LIST}--dropdown`,
            isOpen && 'is-active'
          )}
          style={{ ...dropdownStyles, ...(isOpen ? {} : { border: 0 }) }}
          aria-expanded="false">
          {showSearch && isOpen && (
            <input
              ref={searchRef}
              type="text"
              onChange={e => {
                setSearchTerm(e.target.value)
              }}
              className={cx(
                CLASS_CHOICES_INPUT,
                `${CLASS_CHOICES_INPUT}--cloned`,
                'input input--search'
              )}
              autoComplete="off"
              spellCheck={false}
              aria-autocomplete="list"
              placeholder=""
            />
          )}
          <div className={CLASS_CHOICES_LIST} dir="ltr" role="listbox">
            {selectOptions}
          </div>
        </div>
      </div>
      {messagesOutput}
    </div>
  )
})

export { Select }
