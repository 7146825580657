import React from 'react'
import cx from 'classnames'
import { genResponsiveClasses } from '../utils'

const CLASS_ROOT = 'bar'

type Direction = 'horizontal' | 'vertical'
type Space = 'small' | 'large' | 'xlarge' | 'xxlarge'

export interface BarProps {
  className?: string

  /** Flex vertical alignment */
  align?: 'top' | 'middle' | 'bottom' | 'stretch' | 'left' | 'right'

  /** Bar layout direction. Direction can take enum value or media object. */
  direction?:
    | Direction
    | {
        xl?: Direction
        l?: Direction
        m?: Direction
        s?: Direction
        xs?: Direction
      }

  /** Default direction of bar (hidden prop) */
  defaultDirection?: Direction

  /** Space between items (vertical and horizontal - depends on direction). Space can take enum value or media object. */
  space?:
    | Space
    | {
        xl?: Space
        l?: Space
        m?: Space
        s?: Space
        xs?: Space
      }

  /** Html tag in which the bar rendered. */
  tag?: keyof React.ReactHTML

  /** Disable item wrapping */
  nowrap?: boolean
}

const Bar: React.SFC<BarProps> = ({
  className,
  align,
  defaultDirection = 'horizontal',
  direction,
  space,
  tag = 'div',
  nowrap,
  ...other
}: BarProps) => {
  // assign tag used
  const Tag = tag

  // assign default direction if needed
  let BarDirection = direction || defaultDirection

  // cast BarDirection to object with default(xs) property
  // so it can be merged afterwards with space object
  if (typeof BarDirection === 'string') {
    BarDirection = { xs: BarDirection }
  }

  // merge defaultDirection with passed direction
  BarDirection = { ...{ xs: defaultDirection }, ...BarDirection }

  // cast space to object if needed
  const BarSpace =
    typeof space === 'string'
      ? Object.keys(BarDirection).reduce((acc, breakpoint) => {
          ;(acc as any)[breakpoint] = space
          return acc
        }, {})
      : space || {}

  // merge space with direction or
  // defaultDirection if direction is not specified for that space
  const BarSpaceWithDirection = Object.keys(BarSpace).reduce((acc, breakpoint) => {
    const breakpointDirection = (BarDirection as any)[breakpoint] || defaultDirection
    ;(acc as any)[breakpoint] = `${breakpointDirection}-${(BarSpace as any)[breakpoint]}`
    return acc
  }, {})

  // finally merge direction and space togather
  const BarDirectionAndSpace = { ...BarDirection, ...BarSpaceWithDirection }

  const classes = cx(
    CLASS_ROOT,
    {
      [`align-items-${align}`]: align,
      [`${CLASS_ROOT}--nowrap`]: nowrap,
    },
    ...genResponsiveClasses(CLASS_ROOT, BarDirectionAndSpace),
    className
  )

  return React.createElement(Tag, { className: classes, ...other })
}

export { Bar }
