import React, { Children, ReactNode } from 'react'
import cx from 'classnames'

import { Addon } from './Addon'

const CLASS_ROOT = 'input-addons'

function getAddons(addons: ReactNode) {
  return Children.map(addons, element =>
    // FIXME:
    // console.log(element.type.displayName, Addon.displayName) ||
    (element as any).type && (element as any).type.displayName === Addon.displayName ? (
      element
    ) : (
      <Addon>{element}</Addon>
    )
  )
}

interface AddonsOwnProps {
  left?: React.ReactNode
  right?: React.ReactNode
  size?: 'small' | 'large' | 'huge'

  className?: string
}

const Addons: React.SFC<AddonsOwnProps> = ({
  children,
  className,
  left,
  right,
  size,
  ...other
}) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${size}`]: size,
    },
    className
  )

  return (
    <div className={classes} {...other}>
      {left && getAddons(left)}
      {children}
      {right && getAddons(right)}
    </div>
  )
}

export { Addons }
