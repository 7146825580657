import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import cx from 'classnames'
import { Trans } from '@lingui/macro'
import { Checkbox } from '../forms/radio-check/RadioCheck'
import { Icon } from '../icon'
import { Loader } from '..'
import { useWindowSize } from '../../utils/hooks/useWindowResize'

const CLASS_ROOT = 'table'

type StringOrNumberOnlyKeys<T> = {
  [P in keyof T]: string extends T[P] ? P : number extends T[P] ? P : never
}[keyof T]

export type Column<T> = {
  key: keyof T
  label?: React.ReactNode
  thClasses?: string
  tdClasses?: string
  noSort?: boolean
  colSpan?: number
  hideColumn?: boolean
  render?: (data: T) => ReactNode
  renderHeader?: () => ReactNode
  // sortValue?: (data: T) => string | number
}

interface TableProps<T> {
  /** Is set, if the table doesn't have header. */
  headDisabled?: boolean
  /** Head sizes */
  headSize?: 'space'
  /** Labels for columns in the table. Value of the key in columns match with the key name in data */
  columns: Column<T>[]
  /** Data to print out in the table. */
  data: T[]
  /** Table rows selectable */
  selectable?: boolean
  /** Table rows sortable */
  sortable?: boolean
  /** Table notice */
  notice?: React.ReactNode
  /** Stripe background color reversed */
  bgReverse?: boolean

  /**
   * Record of {column name: sort order}
   */
  sortedColumns?: Partial<Record<keyof T, 'asc' | 'desc' | undefined>>
  sortColumn?: (columnKey: keyof T) => void

  /** will be used as row's key `<tr key={columns[rowKey]} />` */
  rowKey: StringOrNumberOnlyKeys<T>

  selected?: string[]

  onRowSelect?: (selected: string, checked?: boolean) => void

  onAllRowsSelect?: (selected: boolean) => void

  allPageRowsSelected?: boolean

  emptyTableMarkup?: React.ReactNode

  showLoading?: boolean
  loading?: boolean
}

type Props<T> = React.HTMLAttributes<HTMLTableElement> & TableProps<T>

// https://github.com/mobxjs/mobx-react-lite/issues/31
export const Table: <T extends Record<'id', string>>(p: Props<T>) => any = ({
  className,
  columns: columnsRaw,
  data,
  headDisabled = false,
  selectable,
  sortable,
  notice,
  bgReverse,
  headSize,
  sortedColumns: sortDefs,
  sortColumn: setSortDef,
  rowKey,
  selected = [],
  onRowSelect = () => ({}),
  onAllRowsSelect = () => ({}),
  allPageRowsSelected,
  emptyTableMarkup = <Trans>Tabuľka neobsahuje žiadne údaje</Trans>,
  showLoading,
  loading,
  ...other
}) => {
  const columns = columnsRaw.filter(col => !col.hideColumn)

  const handleSelect = useCallback(
    (id: string, checked: boolean) => {
      onRowSelect(id, checked)
    },
    [onRowSelect]
  )

  const handleAllSelect = useCallback(
    (state: boolean) => {
      onAllRowsSelect(state)
    },
    [onAllRowsSelect]
  )

  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--reversed`]: bgReverse,
    },
    className
  )

  function noLabel(element: Column<any>) {
    return element.label === ''
  }

  const tableHead =
    headDisabled || columns.every(noLabel) ? null : (
      <thead
        className={cx(`${CLASS_ROOT}__head`, {
          [`${CLASS_ROOT}__head--${headSize}`]: headSize,
        })}>
        <tr>
          {selectable && (
            <th className={`${CLASS_ROOT}__checkbox`}>
              <Checkbox
                id="check-page"
                size="small"
                formControlClass="mb-none"
                isChecked={allPageRowsSelected}
                onChange={e => handleAllSelect(e.currentTarget.checked)}
              />
            </th>
          )}
          {columns.map(column => {
            const columnSortable = sortable && !column.noSort

            const headClasses = cx(column.thClasses, {
              clickable: columnSortable,
            })

            let arrow = null

            if (sortDefs) {
              if (sortDefs[column.key] === 'asc') {
                arrow = (
                  <Icon
                    name="triangle-up"
                    className="text-vam mb-tiny icon--right"
                    style={{ minWidth: '16px' }}
                  />
                )
              } else if (sortDefs[column.key] === 'desc') {
                arrow = (
                  <Icon
                    name="triangle-down"
                    className="text-vam mb-tiny icon--right"
                    style={{ minWidth: '16px' }}
                  />
                )
              }
            }

            return (
              <th
                key={column.key as any}
                className={headClasses}
                onClick={_ => {
                  // eslint-disable-next-line no-unused-expressions
                  columnSortable && setSortDef && setSortDef(column.key)
                }}>
                {column.renderHeader ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {column.renderHeader()}
                    {columnSortable && arrow}
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {column.label}
                    {/* FIXME: triangle-up in svg is wrong */}
                    {/* <Icon name="triangle-down" className="text-vam mb-tiny icon--right" /> */}
                    {columnSortable && arrow}
                  </div>
                )}
              </th>
            )
          })}
        </tr>
      </thead>
    )

  const columnsLength = selectable ? columns.length + 1 : columns.length

  const staticNoticeRef = useRef<HTMLDivElement>(null)
  const tableNoticeRef = useRef<HTMLTableRowElement>(null)
  const tableBodyRef = useRef<HTMLTableSectionElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const tableNotice = notice && (
    <tr ref={tableNoticeRef} className={`${CLASS_ROOT}__notice`}>
      <td colSpan={columnsLength}>{notice}</td>
    </tr>
  )

  const staticNotice = notice && (
    <div
      ref={staticNoticeRef}
      className={`${CLASS_ROOT}__notice static`}
      style={{
        position: 'fixed',
        zIndex: '100',
        top: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1.8rem 0',
        transition: 'all 0.2s linear',
        opacity: '0',
        transform: 'translateY(-100px)',
      }}>
      {notice}
    </div>
  )

  const size = useWindowSize()

  useEffect(() => {
    if (size?.width && size?.width <= 478) {
      return
    }

    if (!staticNoticeRef.current || !tableNoticeRef.current || !tableContainerRef.current) {
      return
    }

    staticNoticeRef.current.style.width = `${
      tableContainerRef.current.getBoundingClientRect().width
    }px`
  }, [size])

  useEffect(() => {
    if (size?.width && size.width <= 478) {
      return
    }

    const intersectionCallback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (!staticNoticeRef.current || !tableNoticeRef.current || !tableBodyRef.current) {
          return
        }

        if (entry.intersectionRatio >= 0.1) {
          staticNoticeRef.current.style.opacity = '0'
          staticNoticeRef.current!.style.transform = 'translateY(-100px)'
        } else if (entry.intersectionRatio <= 0) {
          staticNoticeRef.current!.style.opacity = '1'
          staticNoticeRef.current!.style.transform = 'translateY(0px)'
        }
      })
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.1, 1],
    }

    const observer = new IntersectionObserver(intersectionCallback, options)
    if (tableNoticeRef?.current) {
      observer.observe(tableNoticeRef.current)
    }
  }, [size, notice])

  const table = (
    <>
      {notice && staticNotice}
      <div className="table-container" ref={tableContainerRef}>
        <table className={classes} {...other}>
          {tableHead}
          <tbody ref={tableBodyRef}>
            {tableNotice}
            {showLoading && loading && (
              <tr>
                <td colSpan={9}>
                  <Loader
                    size="large"
                    loaderWrapperProps={{
                      style: {
                        height: '300px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                    }}
                  />
                </td>
              </tr>
            )}
            {(!showLoading || !loading) && (
              <>
                {data.length ? (
                  data.map(row => {
                    const key = (row[rowKey] as unknown) as string | number

                    return (
                      <tr key={key}>
                        {selectable && (
                          <td>
                            <Checkbox
                              id={`check-row-${key}`}
                              name={`check-row-${key}`}
                              size="small"
                              formControlClass="mb-none"
                              isChecked={selected.includes(row.id)}
                              onChange={e => handleSelect(row.id, e.currentTarget.checked)}
                            />
                          </td>
                        )}
                        {columns.map(column => {
                          return (
                            <td
                              key={column.key as any}
                              colSpan={column.colSpan}
                              className={column.tdClasses}>
                              {column.render ? column.render(row) : (row as any)[column.key]}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                ) : (
                  <tr className={`${CLASS_ROOT}__row--empty`}>
                    <td colSpan={columnsLength}>{emptyTableMarkup}</td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  )

  return table
}
