import React from 'react'
import cx from 'classnames'

export interface IconOwnProps {
  /** label text for screen readers. used for standalone icons that do not have accopanying text */
  label?: string

  /** icon name */
  name?: string

  /** icon size */
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
}

const CLASS_ROOT = 'icon'

export type IconProps = React.SVGAttributes<HTMLOrSVGElement> & IconOwnProps

export const Icon: React.SFC<IconProps> = ({
  className,
  name,
  size,
  label,

  ...other
}) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--${size}`]: size,
      [`${CLASS_ROOT}--${name}`]: name,
    },
    className
  )

  return (
    <svg
      className={classes}
      role={label ? 'img' : 'presentation'}
      {...(label ? { 'aria-label': label } : { 'aria-hidden': 'true' })}
      {...other}>
      {label && <title>{label}</title>}
      <use xlinkHref={`#${name}`} />
    </svg>
  )
}
