import React from 'react'
import cx from 'classnames'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  type?: 'action' | 'filled'
}

const CLASS_ROOT = 'input-addons__item'

const Addon: React.SFC<Props> = ({ className, type, ...other }) => {
  const classes = cx(CLASS_ROOT, `${CLASS_ROOT}--${type}`, className)

  return <div className={classes} {...other} />
}

// this is probably still used in Addons.tsx:12?
Addon.displayName = 'Addon'

export { Addon }
