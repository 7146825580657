import React from 'react'
import cx from 'classnames'

const CLASS_ROOT = 'overlay'

interface OverlayProps {
  className?: string
  children?: React.ReactNode
  /** Background image src */
  bgSrc: string
  srcSet?: string

  /** Keep specified aspect ratio of the container */
  aspectRatio?: number
  /** Fill the nearest relatively positioned element with overlay */
  fullScreen?: boolean
  /** Overlay tint */
  color?: 'transparent'
  /** Applies custom classNames to overlay content */
  contentClass?: string
  /** Custom sizing  */
  size?: 'fixed'
  /** Fill height */
  fill?: boolean
  imageOnTopRight?: boolean
  imgStyle?: object
  imgClass?: string
}

type Props = React.HTMLAttributes<HTMLImageElement> & OverlayProps

const Overlay: React.SFC<Props> = ({
  className,
  aspectRatio,
  bgSrc,
  fullScreen,
  children,
  color,
  contentClass,
  size,
  fill,
  srcSet,
  imageOnTopRight,
  imgStyle,
  imgClass,
  ...other
}) => {
  const classes = cx(
    CLASS_ROOT,
    {
      [`${CLASS_ROOT}--fill`]: fill,
      [`${CLASS_ROOT}--fullscreen`]: fullScreen,
      [`${CLASS_ROOT}--${color}`]: color,
      [`${CLASS_ROOT}--has-aspect-ratio`]: aspectRatio,
    },
    className
  )

  const contentClasses = cx(
    `${CLASS_ROOT}__content`,
    {
      [`${CLASS_ROOT}__content--${size}`]: size,
    },
    contentClass
  )

  let styles = {}

  if (aspectRatio) {
    const padding = `${aspectRatio ** -1 * 100}%`

    styles = { ...styles, paddingTop: padding }
  }

  const bgImgClassName = cx(`${CLASS_ROOT}__bg`, {
    [`${CLASS_ROOT}__bg--image-on-top-right`]: imageOnTopRight,
  })

  return (
    <div className={classes} style={styles}>
      <div className={contentClasses}>{children}</div>
      <div className={`${CLASS_ROOT}__image-wrapper`}>
        {!!bgSrc && (
          <img
            src={bgSrc}
            srcSet={srcSet}
            className={imgClass || bgImgClassName}
            aria-hidden="true"
            alt=""
            style={imgStyle}
            {...other}
          />
        )}
      </div>
    </div>
  )
}

export { Overlay }
